import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup
} from "@material-ui/core";
import { Button } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";

interface IProps {
    dialogOpen: boolean;
    setDialogOpen: Dispatch<SetStateAction<boolean>>;
    visitationAction: () => void;
    headerText: string;
    contextText: string;
    buttonText: string;
    cancelText: string;
    keyText: string;
    enableCheckbox: boolean;
}

export default function EndVisitationDialog(props: IProps): React.ReactElement<IProps> {
    const [checkboxChecked, setCheckboxChecked] = useState(false);

    const handleClose = () => {
        props.setDialogOpen(false);
        setCheckboxChecked(false);
    };

    return (
        <Dialog
            open={props.dialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{props.headerText}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{props.contextText}</DialogContentText>
                {props.enableCheckbox ? (
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox value={checkboxChecked} onClick={() => setCheckboxChecked(!checkboxChecked)} />}
                            label={props.keyText}
                            style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        />
                    </FormGroup>
                ) : (
                    <></>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{props.cancelText}</Button>
                <Button
                    disabled={props.enableCheckbox ? !checkboxChecked : false}
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        props.visitationAction();
                        props.setDialogOpen(false);
                    }}
                    autoFocus>
                    {props.buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
