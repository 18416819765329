import Navbar from "./navbar/Navbar";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import VisitationStatus from "../visitations/guestPages/VisitationStatus";
import GuestAuthorizationPrompt from "../visitations/guestPages/GuestAuthorizationPrompt";
import PrivateRoute from "./PrivateRoute";
import ActiveVisitationContextWrapper from "../visitations/activeVisitation/ActiveVisitationContextWrapper";
import VisitationHistoryContextWrapper from "../visitations/visitationHistory/VisitationHistoryContextWrapper";
import Login from "./Login";
import VisitationRegistration from "../visitations/registration/VisitationRegistration";
import Footer from "./Footer";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import AccessKeyManagement from "../accessKey/AccessKeyManagement";
import VisitationEnded from "../visitations/guestPages/VisitationEnded";

export default function Routing(): React.ReactElement {
    const appContext = useContext(AppContext);
    return (
        <Router>
            <Navbar />
            <main role="main" className="main">
                <Switch>
                    <Route
                        exact
                        path="/"
                        component={() => {
                            if (appContext.isAuthenticated) {
                                return <Redirect to="active-visitations" />;
                            } else if (appContext.isAuthorizedGuest && !appContext.isRegisteredGuest) {
                                return <Redirect to="/register" />;
                            } else if (appContext.isAuthorizedGuest && appContext.isRegisteredGuest) {
                                return <VisitationStatus />;
                            }
                            return <Redirect to="/authorization" />;
                        }}
                    />

                    <Route
                        exact
                        path="/authorization"
                        component={() => {
                            if (appContext.isAuthorizedGuest && appContext.isRegisteredGuest) {
                                return <Redirect to="/" />;
                            } else if (appContext.isAuthorizedGuest && !appContext.isRegisteredGuest) {
                                return <Redirect to="/register" />;
                            }
                            return <GuestAuthorizationPrompt />;
                        }}
                    />

                    <PrivateRoute
                        exact
                        path="/access-key"
                        isAuthenticated={appContext.isAuthenticated}
                        component={AccessKeyManagement}
                        redirect={<Redirect to={{ pathname: "/login" }} />}></PrivateRoute>

                    <PrivateRoute
                        exact
                        path="/active-visitations"
                        isAuthenticated={appContext.isAuthenticated}
                        component={ActiveVisitationContextWrapper}
                        redirect={<Redirect to={{ pathname: "/login" }} />}></PrivateRoute>

                    <PrivateRoute
                        exact
                        path="/visitation-history"
                        isAuthenticated={appContext.isAuthenticated}
                        component={VisitationHistoryContextWrapper}
                        redirect={<Redirect to={{ pathname: "/login" }} />}></PrivateRoute>

                    <Route path="/login">
                        <Login />
                    </Route>

                    <Route
                        path="/register"
                        component={() => {
                            if ((appContext.isAuthorizedGuest && !appContext.isRegisteredGuest) || appContext.isAuthenticated) {
                                return <VisitationRegistration />;
                            }
                            return <Redirect to="/authorization" />;
                        }}
                    />

                    <Route
                        path="/visitation-finished"
                        component={() => {
                            if (appContext.isAuthorizedGuest && !appContext.isRegisteredGuest) {
                                return <Redirect to="/register" />;
                            }
                            return <VisitationEnded />;
                        }}
                    />
                </Switch>
                <Footer />
            </main>
        </Router>
    );
}
