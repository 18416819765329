export interface ILayoutResourceStrings {
    deleteVisitation: string;
    contextText: string;
    buttonText: string;
    cancelText: string;
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings;
}

const engb = "en-GB";
const etee = "et-EE";
const ruru = "ru-RU";

export const VisitationHistoryRowTranslations: ILayoutResources = {
    [engb]: {
        deleteVisitation: "Delete visitation",
        contextText: "Are you sure that you want to delete the visitation? The entry will be deleted from the database after this action.",
        buttonText: "Delete",
        cancelText: "Cancel"
    },
    [ruru]: {
        deleteVisitation: "Удалить посещение",
        contextText: "Вы уверены, что хотите удалить посещение? Запись будет удалена из базы данных после этого действия.",
        buttonText: "Удалить",
        cancelText: "Отмена"
    },
    [etee]: {
        deleteVisitation: "Kustuta külastus",
        contextText: "Oled kindel, et soovid külastussissekannet kustutada? Peale seda tegevust eemaldatakse kirje andmebaasist.",
        buttonText: "Kustuta",
        cancelText: "Tühista"
    }
};

export default VisitationHistoryRowTranslations;
