import React from "react";
import IVisitation from "../../../model/IVisitation";
import { IVisitationPage } from "../../../model/IVisitationPage";

export interface IVisitationHistoryContext {
    visitations: IVisitation[];
    visitationPage: IVisitationPage | null;
    setVisitations: (visitations: IVisitation[]) => void;
    setVisitationPage: (page: IVisitationPage) => void;
}

export const VisitationHistoryContextInitialState: IVisitationHistoryContext = {
    visitations: [],
    visitationPage: null,
    setVisitations: () => undefined,
    setVisitationPage: () => undefined
};

export const VisitationContext = React.createContext<IVisitationHistoryContext>(VisitationHistoryContextInitialState);
