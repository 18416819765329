export interface ILayoutResourceStrings {
    visitationHistory: string;
    placeholderHeader: string;
    placeholderBody: string;
    placeholderBold: string;
    placeholderBodySecond: string;
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings;
}

const engb = "en-GB";
const etee = "et-EE";
const ruru = "ru-RU";

export const VisitationHistoryTranslations: ILayoutResources = {
    [engb]: {
        visitationHistory: "Visitation history",
        placeholderHeader: "Theres nothing here...",
        placeholderBody: "No visitations to display. Refresh the page or create an entry from the",
        placeholderBold: "Add visitation",
        placeholderBodySecond: " page"
    },
    [ruru]: {
        visitationHistory: "История посещений",
        placeholderHeader: "Здесь сейчас ничего...",
        placeholderBody: "Нет записей о посещении. Пожалуйста, обновите страницу или сделайте запись в окне ",
        placeholderBold: "Добавить посещение",
        placeholderBodySecond: ""
    },
    [etee]: {
        visitationHistory: "Külastuste ajalugu",
        placeholderHeader: "Siin pole hetkel midagi...",
        placeholderBody: "Külastussissekanded puuduvad. Värskenda lehte või tee sissekanne",
        placeholderBold: "Lisa külastus",
        placeholderBodySecond: " aknast"
    }
};

export default VisitationHistoryTranslations;
