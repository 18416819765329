export interface ILayoutResourceStrings {
    data: string;
    name: string;
    phoneNumber: string;
    companyName: string;
    visitationReason: string;
    vehicleRegistrationNumber: string;
    from: string;
    until: string;
    hasKey: string;
    yes: string;
    no: string;
    email: string;
    comment: string;
    delete: string;
    endVisitation: string;
    contextText: string;
    buttonText: string;
    cancelText: string;
    notFound: string;
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings;
}

const engb = "en-GB";
const etee = "et-EE";
const ruru = "ru-RU";

export const CollapsibleDetailTranslations: ILayoutResources = {
    [engb]: {
        data: "Data",
        name: "Name",
        phoneNumber: "Phone number",
        companyName: "Company name",
        visitationReason: "Visitation reason",
        vehicleRegistrationNumber: "Vehicle registration number",
        from: "From",
        until: "Until",
        hasKey: "Has key?",
        yes: "Yes",
        no: "No",
        email: "E-mail",
        comment: "Comment",
        endVisitation: "End visitation",
        delete: "Delete visitation",
        contextText: "Are you sure that you want to finish the visitation?",
        buttonText: "Yes",
        cancelText: "Cancel",
        notFound: "Could not retrieve visitation data."
    },
    [ruru]: {
        data: "Данные",
        name: "Имя",
        phoneNumber: "Номер телефона",
        companyName: "Компания",
        visitationReason: "Цель визита",
        vehicleRegistrationNumber: "Регистр автомобиля номер",
        from: "От",
        until: "До",
        hasKey: "Ключ получен?",
        yes: "Да",
        no: "Нет",
        email: "е-мейл",
        comment: "Комментарий",
        endVisitation: "Завершить посещение",
        delete: "Удалить посещение",
        contextText: "Вы уверены, что хотите завершить визит?",
        buttonText: "Да",
        cancelText: "Отмена",
        notFound: "Не удалось получить данные о посещениях."
    },
    [etee]: {
        data: "Andmed",
        name: "Nimi",
        phoneNumber: "Telefoni nr.",
        companyName: "Ettevõtte nimi",
        visitationReason: "Külastuse põhjus",
        vehicleRegistrationNumber: "Sõiduki reg. nr",
        from: "Alates",
        until: "Kuni",
        hasKey: "Sai võtme?",
        yes: "Jah",
        no: "Ei",
        email: "E-mail",
        comment: "Kommentaar",
        endVisitation: "Lõpeta külastus",
        delete: "Kustuta külastus",
        contextText: "Oled kindel, et soovid külastust lõpetada?",
        buttonText: "Lõpeta",
        cancelText: "Tühista",
        notFound: "Ei leidnud külastuse andmeid."
    }
};

export default CollapsibleDetailTranslations;
