import React, { useState } from "react";
import { VisitationContext } from "./VisitationContext";
import IVisitation from "../../../model/IVisitation";
import { IVisitationPage } from "../../../model/IVisitationPage";

interface IProps {
    children: React.ReactNode;
}

export default function VisitationContextProvider(props: IProps): React.ReactElement<IProps> {
    const [state, setState] = useState({
        visitations: [] as IVisitation[],
        page: { number: 0 } as IVisitationPage
    });

    const setVisitations = (visitations: IVisitation[]) => {
        setState({ ...state, visitations });
    };

    const setVisitationPage = (page: IVisitationPage) => {
        setState({ ...state, page: page });
    };

    return (
        <VisitationContext.Provider
            value={{
                visitations: state.visitations,
                setVisitations,
                visitationPage: state.page,
                setVisitationPage: setVisitationPage
            }}>
            {props.children}
        </VisitationContext.Provider>
    );
}
