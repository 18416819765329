import { Card, Fade, Grid } from "@mui/material";
import { StyledButtonPrimaryWide, StyledInputField, StyledInputLabel } from "../shared/VisitationStyles";
import { useContext, useEffect, useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import { ErrorMessage, Form, Formik } from "formik";
import LoginIcon from "@mui/icons-material/Login";
import { AppContext } from "../../../context/AppContext";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import GuestAuthorizationPromptTranslations from "../../../translations/visitations/guestPages/GuestAuthorizationPrompt";
import { AccessKeyService } from "../../../services/AccessKeyService";

export default function GuestAuthorizationPrompt(): React.ReactElement {
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const appContext = useContext(AppContext);
    const history = useHistory();
    const resource = GuestAuthorizationPromptTranslations;
    const culture = appContext.selectedCulture;

    useEffect(() => {
        const toggleFade = () => {
            setIsDataLoaded(true);
        };
        toggleFade();
    }, [appContext.selectedCulture]);

    const validateInput = async (value: string | undefined) => {
        if (value === null) {
            return false;
        }
        const response = await AccessKeyService.validateAccessKey({ value: value! });
        return response === 200;
    };

    const validationSchema = Yup.object({
        code: Yup.string()
            .test("validate", resource[culture].codeExpired, (value) => validateInput(value))
            .required(resource[culture].enterCode)
    });

    const renderError = (message: string) => {
        return <div style={{ fontSize: 14, color: "#EF8354", textAlign: "center" }}>{message}</div>;
    };

    const handleSubmit = async () => {
        appContext.setIsAuthorizedGuest(true);
        history.push("/register");
    };

    return (
        <Fade in={isDataLoaded}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={8} sm={4} md={3} lg={2} xl={2}>
                    <Card style={{ marginTop: "4rem" }}>
                        <Grid container alignItems="center" justifyContent="center">
                            <LockIcon style={{ width: "100%", padding: "2rem 0 0 0" }} fontSize={"large"} />
                            <Formik
                                initialValues={{ code: "" }}
                                validationSchema={validationSchema}
                                validateOnChange={false}
                                validateOnBlur={false}
                                onSubmit={(values, { resetForm }) => handleSubmit().then(() => resetForm())}>
                                <Form style={{ padding: "1rem 0 2rem" }}>
                                    <div>
                                        <StyledInputLabel style={{ textAlign: "center" }}>{resource[culture].password}</StyledInputLabel>
                                        <StyledInputField style={{ textAlign: "center" }} name="code" type="password" />
                                        <ErrorMessage name="code" render={renderError} />
                                    </div>
                                    <div style={{ marginTop: "1rem" }}>
                                        <StyledButtonPrimaryWide variant="contained" type="submit">
                                            {resource[culture].enter} <LoginIcon />
                                        </StyledButtonPrimaryWide>
                                    </div>
                                </Form>
                            </Formik>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Fade>
    );
}
