import { Box, Button, Card, InputLabel, styled, TableCell, tableCellClasses, TableRow } from "@mui/material";
import { Field } from "formik";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4F5D75",
        fontWeight: "bold",
        color: "#FFF",
        fontSize: 16,
        fontFamily: `"Open Sans", sans-serif;`
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: `"Open Sans", sans-serif;`,
        border: 0
    }
}));

const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#FCFCFC"
    },
    "&:nth-of-type(even)": {
        backgroundColor: "#efefef"
    }
}));

const StyledTableBox = styled(Box)(() => ({
    margin: "1rem"
}));

const StyledCard = styled(Card)(() => ({
    minHeight: "50vh",
    borderRadius: "10px"
}));

const StyledInputField = styled(Field)(() => ({
    width: "100%",
    boxSizing: "border-box",
    padding: "0.5rem",
    border: "1px solid #BFC0C0",
    borderRadius: "4px",
    fontSize: "1rem",
    fontWeight: 300,
    color: "#343434",
    "&:focus": {
        outlineColor: "#4F5D75"
    },
    "::-webkit-input-placeholder": {
        color: "#989898"
    },
    ":-moz-placeholder": {
        color: "#989898"
    },
    "::-moz-placeholder": {
        color: "#989898",
        opacity: 1
    },
    ":-ms-input-placeholder": {
        color: "#989898"
    },
    "::-ms-input-placeholder": {
        color: "#989898"
    },
    "::placeholder": {
        color: "#989898"
    }
}));

const StyledInputLabel = styled(InputLabel)(() => ({
    display: "block",
    fontWeight: "bold",
    padding: "10px 0px",
    color: "#505050",
    letterSpacing: "normal"
}));

const StyledInputLabelRequired = styled(InputLabel)(() => ({
    display: "block",
    fontWeight: "bold",
    padding: "10px 0px",
    color: "#505050",
    letterSpacing: "normal",
    "&:after": {
        content: '"*"',
        color: "#EF8354"
    }
}));

const StyledInlineCheckboxLabel = styled(StyledInputLabel)(() => ({
    display: "unset",
    whiteSpace: "normal"
}));

const StyledInlineInputLabel = styled(StyledInputLabel)(() => ({
    margin: "auto 0 0",
    whiteSpace: "unset",
    letterSpacing: "normal"
}));

const StyledInlineInputLabelRequired = styled(StyledInputLabel)(() => ({
    margin: "auto 0 0",
    whiteSpace: "unset",
    letterSpacing: "normal",
    "&:after": {
        content: '"*"',
        color: "#EF8354"
    }
}));

const StyledButtonPrimary = styled(Button)(() => ({
    backgroundColor: "#ff986c",
    fontSize: "1rem",
    padding: "10px 20px",
    "&:hover": {
        backgroundColor: "#bb6d48"
    }
}));

const StyledButtonPrimaryWide = styled(StyledButtonPrimary)(() => ({
    width: "100%"
}));

const StyledButtonSecondary = styled(Button)(() => ({
    backgroundColor: "#BFC0C0",
    fontSize: "1rem",
    padding: "10px 20px",
    "&:hover": {
        backgroundColor: "#adadad"
    }
}));

const StyledButtonSecondaryWide = styled(StyledButtonSecondary)(() => ({
    width: "100%"
}));

export {
    StyledTableCell,
    StyledTableRow,
    StyledTableBox,
    StyledCard,
    StyledInputField,
    StyledInputLabel,
    StyledInputLabelRequired,
    StyledInlineInputLabel,
    StyledInlineInputLabelRequired,
    StyledInlineCheckboxLabel,
    StyledButtonPrimary,
    StyledButtonPrimaryWide,
    StyledButtonSecondary,
    StyledButtonSecondaryWide
};
