import { Dialog, DialogTitle } from "@material-ui/core";
import { Button, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

interface IProps {
    dialogOpen: boolean;
    setDialogOpen: Dispatch<SetStateAction<boolean>>;
    visitationAction: () => void;
    headerText: string;
    contextText: string;
    buttonText: string;
    cancelText: string;
}

export default function VisitationActionDialog(props: IProps): React.ReactElement<IProps> {
    const handleClose = () => {
        props.setDialogOpen(false);
    };

    return (
        <Dialog
            open={props.dialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{props.headerText}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{props.contextText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{props.cancelText}</Button>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        props.visitationAction();
                        props.setDialogOpen(false);
                    }}
                    autoFocus>
                    {props.buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
