const DateFormatter = (date: Date): string => {
    return date.toLocaleTimeString() + ", " + date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
};

const DateIsMoreThan24HoursAgo = (date: string | null): boolean => {
    if (date === null) return false;
    const day = 1000 * 60 * 60 * 24;
    const dayAgo = Date.now() - day;
    const result = new Date(date).getTime() < dayAgo;
    return result;
};

const GetCurrentDateEpochSeconds = (): number => {
    return Math.floor(Date.now() / 1000);
};

export { DateFormatter, DateIsMoreThan24HoursAgo, GetCurrentDateEpochSeconds };
