import { IAppContext } from "./AppContext";

export const loadState = (): IAppContext | null => {
    try {
        const serializedState = localStorage.getItem("state");
        if (serializedState === null) {
            return null;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return null;
    }
};

export const saveState = (state: IAppContext): void => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("state", serializedState);
    } catch (err) {
        // ignore
    }
};

export const deleteState = (): void => {
    try {
        localStorage.removeItem("state");
    } catch (err) {
        // ignore
    }
};
