export interface ILayoutResourceStrings {
    endVisitation: string;
    contextText: string;
    buttonText: string;
    cancelText: string;
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings;
}

const engb = "en-GB";
const etee = "et-EE";
const ruru = "ru-RU";

export const ActiveVisitationRowTranslations: ILayoutResources = {
    [engb]: {
        endVisitation: "End visitation",
        contextText: "Are you sure that you want to finish the visitation?",
        buttonText: "Yes",
        cancelText: "Cancel"
    },
    [ruru]: {
        endVisitation: "Завершить посещение",
        contextText: "Вы уверены, что хотите завершить визит?",
        buttonText: "Да",
        cancelText: "Отмена"
    },
    [etee]: {
        endVisitation: "Lõpeta külastus",
        contextText: "Oled kindel, et soovid külastust lõpetada?",
        buttonText: "Lõpeta",
        cancelText: "Tühista"
    }
};

export default ActiveVisitationRowTranslations;
