import { Divider, Fade } from "@mui/material";
import { Grid } from "@material-ui/core";
import CardHeader from "../../shared/CardHeader";
import CollapsibleTable from "../shared/CollapsibleTable";
import { useContext, useEffect, useState } from "react";
import { VisitationService } from "../../../services/VisitationService";
import { AppContext } from "../../../context/AppContext";
import VisitationHistoryTableHeader from "./VisitationHistoryTableHeader";
import { VisitationContext } from "../context/VisitationContext";
import { StyledCard, StyledTableBox } from "../shared/VisitationStyles";
import VisitationPlaceholder from "../shared/VisitationPlaceholder";
import VisitationHistoryTranslations from "../../../translations/visitationHistory/VisitationHistory";

export default function VisitationHistory(): React.ReactElement {
    const appContext = useContext(AppContext);
    const resource = VisitationHistoryTranslations;
    const culture = appContext.selectedCulture;
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const context = useContext(VisitationContext);

    useEffect(() => {
        const callApi = async () => {
            const page = await VisitationService.getAllVisitations(appContext.jwt!, context.visitationPage?.number ?? 0, 10);
            if (page) {
                context.setVisitationPage(page);
            }
        };
        callApi().then(() => setIsDataLoaded(true));
        // eslint-disable-next-line
    }, [appContext.jwt, context.visitationPage?.number]);

    return (
        <Fade in={isDataLoaded}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12} lg={8} xl={8}>
                    <StyledCard>
                        <CardHeader text={resource[culture].visitationHistory} />
                        <Divider variant={"middle"} />
                        <StyledTableBox>
                            {context.visitationPage?.content && context.visitationPage?.content.length > 0 ? (
                                <CollapsibleTable activeRow={false} tableHeader={VisitationHistoryTableHeader} />
                            ) : (
                                <VisitationPlaceholder
                                    headerText={resource[culture].placeholderHeader}
                                    fillerText={resource[culture].placeholderBody}
                                    boldText={resource[culture].placeholderBold}
                                    fillerTextSecondary={resource[culture].placeholderBodySecond}
                                />
                            )}
                        </StyledTableBox>
                    </StyledCard>
                </Grid>
            </Grid>
        </Fade>
    );
}
