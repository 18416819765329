import { Box, Collapse, Divider, ListItem, TableRow, Typography } from "@mui/material";
import GridItem from "./GridItem";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import KeyIcon from "@mui/icons-material/Key";
import EmailIcon from "@mui/icons-material/Email";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import IVisitation from "../../../model/IVisitation";
import { Button, Grid, makeStyles, TableCell } from "@material-ui/core";
import { Dispatch, SetStateAction, useContext } from "react";
import { DateFormatter } from "../../../utils/DateUtils";
import { AppContext } from "../../../context/AppContext";
import CollapsibleDetailTranslations from "../../../translations/shared/CollapsibleDetail";

interface IProps {
    row: IVisitation;
    collapsibleOpen: boolean;
    setCollapsibleOpen: Dispatch<SetStateAction<boolean>>;
    setDialogOpen: Dispatch<SetStateAction<boolean>>;
    historyPage: boolean;
    visitationAction: () => void;
}

const useStyles = makeStyles(() => ({
    buttons: {
        margin: 0,
        paddingTop: "1rem 1rem"
    },
    deleteButton: {
        backgroundColor: "#EF8354",
        color: "#3b3b3b",
        fontSize: "1rem",
        margin: "0rem 1rem 0rem 0rem",
        "&:hover": {
            backgroundColor: "#b96744"
        }
    },
    editButton: {
        backgroundColor: "#BFC0C0",
        color: "#3b3b3b",
        fontSize: "1rem",
        margin: "0rem 1rem 0rem 0rem",
        "&:hover": {
            backgroundColor: "#8c8c8c"
        }
    },
    tc: {
        padding: 0
    },
    header: {
        padding: "8px"
    },
    grid: {
        margin: 0,
        padding: "1rem 0"
    }
}));

export default function CollapsibleDetails(props: IProps): React.ReactElement<IProps> {
    const date = new Date(props.row.arrived);
    const exitDate = props.row.departed ? new Date(props.row.departed) : null;
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const resource = CollapsibleDetailTranslations;
    const culture = appContext.selectedCulture;

    return (
        <TableRow>
            <TableCell className={classes.tc} colSpan={6}>
                <Collapse in={props.collapsibleOpen} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 0, flexGrow: 1, padding: "1rem 3rem" }}>
                        <Typography className={classes.header} variant="h6" gutterBottom component="div">
                            {resource[culture].data}
                        </Typography>
                        {/* Grid viimaste elementide wrappimine tekitab flickerit*/}
                        <Grid className={classes.grid} container spacing={2}>
                            <GridItem
                                primaryText={resource[culture].visitationReason}
                                secondaryText={props.row.visitationReason}
                                svgIcon={QuestionMarkIcon}
                            />
                            <GridItem primaryText={resource[culture].from} secondaryText={DateFormatter(date)} svgIcon={LoginIcon} />
                            {exitDate === null ? (
                                <></>
                            ) : (
                                <GridItem
                                    primaryText={resource[culture].until}
                                    secondaryText={DateFormatter(exitDate)}
                                    svgIcon={LogoutIcon}
                                />
                            )}
                            <GridItem
                                primaryText={resource[culture].vehicleRegistrationNumber}
                                secondaryText={props.row.vehicleRegistrationNumber}
                                svgIcon={DirectionsCarIcon}
                            />
                            {props.row.emailAddress === "" ? (
                                <></>
                            ) : (
                                <GridItem
                                    primaryText={resource[culture].email}
                                    secondaryText={props.row.emailAddress}
                                    svgIcon={EmailIcon}
                                />
                            )}
                            <GridItem
                                primaryText={resource[culture].hasKey}
                                secondaryText={props.row.hasKey ? resource[culture].yes : resource[culture].no}
                                svgIcon={KeyIcon}
                            />
                            {props.row.comment === "" ? (
                                <></>
                            ) : (
                                <GridItem primaryText={resource[culture].comment} secondaryText={props.row.comment} svgIcon={CommentIcon} />
                            )}
                        </Grid>
                        <Divider />
                        <Grid className={classes.grid} container spacing={2}>
                            <Grid className={classes.header} item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <ListItem style={{ padding: 0 }}>
                                    <Button className={classes.deleteButton} onClick={() => props.setDialogOpen(true)} variant="contained">
                                        {props.historyPage ? (
                                            <>
                                                <DeleteIcon /> {resource[culture].delete}
                                            </>
                                        ) : (
                                            <>
                                                <DoneIcon /> {resource[culture].endVisitation}
                                            </>
                                        )}
                                    </Button>
                                </ListItem>
                            </Grid>
                        </Grid>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
}
