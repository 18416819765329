import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    placeholderImage: {
        display: "block",
        margin: " 0 auto",
        width: "12rem",
        padding: "2rem"
    },
    placeholderHeader: {
        textAlign: "center",
        fontWeight: "bold"
    },
    placeholderText: {
        textAlign: "center",
        padding: "1rem 1rem 2rem"
    }
}));

interface IProps {
    headerText: string;
    fillerText: string;
    boldText: string;
    fillerTextSecondary: string;
}

export default function VisitationPlaceholder(props: IProps): React.ReactElement<IProps> {
    const classes = useStyles();

    return (
        <>
            <img src="images/glassPlaceholder.svg" alt="Placeholder for missing visitation records" className={classes.placeholderImage} />
            <Typography className={classes.placeholderHeader} component="h6" variant="h6">
                {props.headerText}
            </Typography>
            <p className={classes.placeholderText}>
                {props.fillerText} <b>`&quot;`{props.boldText}`&quot;`</b>
                {props.fillerTextSecondary}.
            </p>
        </>
    );
}
