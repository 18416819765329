import { Card, Grid, makeStyles, Typography } from "@material-ui/core";
import { AppContext } from "../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import IAccessKey from "../../model/IAccessKey";
import { AccessKeyService } from "../../services/AccessKeyService";
import { Alert, Collapse, Divider, Fade, ListItem, Snackbar } from "@mui/material";
import GridItem from "../visitations/shared/GridItem";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
    StyledButtonPrimaryWide,
    StyledButtonSecondaryWide,
    StyledInputField,
    StyledInputLabel
} from "../visitations/shared/VisitationStyles";
import AccessKeyManagementTranslations from "../../translations/accessKey/AccessKeyManagement";
import { ErrorMessage, Form, Formik } from "formik";
import SaveIcon from "@mui/icons-material/Save";
import * as Yup from "yup";
import { DateFormatter } from "../../utils/DateUtils";

const useStyles = makeStyles(() => ({
    gridContainer: {
        padding: "0 2rem 1rem"
    },
    header: {
        padding: "30px 30px",
        fontWeight: "bold",
        textAlign: "center"
    }
}));

interface FormFields {
    value: string;
}

const initialState = {
    value: ""
};

export default function AccessKeyManagement(): React.ReactElement {
    const appContext = useContext(AppContext);
    const classes = useStyles();
    const [accessKey, setAccessKey] = useState({} as IAccessKey);
    const [isLoaded, setIsLoaded] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);

    const culture = appContext.selectedCulture;
    const resource = AccessKeyManagementTranslations;

    const validationSchema = Yup.object({ value: Yup.string().required(resource[culture].required) });

    const renderError = (message: string) => {
        return <div style={{ fontSize: 14, color: "#EF8354", textAlign: "center" }}>{message}</div>;
    };

    const toggleCollapsible = () => {
        setIsCollapsibleOpen((prev) => !prev);
    };

    const handleSubmit = async (fields: FormFields) => {
        const response = await AccessKeyService.updateAccessKey(appContext.jwt!, { ...accessKey, value: fields.value });
        if (response === 200) {
            setReloadData((prev) => !prev);
            toggleCollapsible();
            setAlertOpen(true);
        }
    };

    useEffect(() => {
        const apiCall = async () => {
            const accessKey = await AccessKeyService.getAccessKey(appContext.jwt!);
            if (accessKey !== null) {
                setAccessKey(accessKey);
            }
        };
        apiCall().then(() => setIsLoaded(true));
    }, [appContext.selectedCulture, appContext.jwt, reloadData]);

    return (
        <Fade in={isLoaded}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={10} md={8} lg={5} xl={4}>
                    <Card>
                        <Typography variant="h5" component="h5" className={classes.header}>
                            {resource[culture].accessKey}
                        </Typography>
                        <Grid container justifyContent="center" className={classes.gridContainer}>
                            <GridItem
                                xs={12}
                                sm={5}
                                md={4}
                                lg={4}
                                xl={4}
                                primaryText={resource[culture].value}
                                secondaryText={accessKey.value}
                                svgIcon={VpnKeyIcon}
                            />
                            <GridItem
                                xs={12}
                                sm={5}
                                md={4}
                                lg={4}
                                xl={4}
                                primaryText={resource[culture].lastChanged}
                                secondaryText={DateFormatter(new Date(accessKey.createdAt))}
                                svgIcon={AccessTimeIcon}
                            />
                            <Divider />
                            <Grid container alignItems={"center"} justifyContent={"center"}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <ListItem>
                                        <StyledButtonSecondaryWide onClick={toggleCollapsible} variant="contained">
                                            {isCollapsibleOpen ? resource[culture].stopEdit : resource[culture].edit}
                                        </StyledButtonSecondaryWide>
                                    </ListItem>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Collapse in={isCollapsibleOpen} timeout="auto" unmountOnExit>
                            <Grid container alignItems="center" justifyContent="center">
                                <Formik
                                    initialValues={initialState as FormFields}
                                    validationSchema={validationSchema}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onSubmit={(values, { resetForm }) => handleSubmit(values).then(() => resetForm())}>
                                    <Form style={{ padding: "1rem 0 2rem" }}>
                                        <div>
                                            <StyledInputLabel style={{ textAlign: "center" }}>
                                                {resource[culture].newPassword}
                                            </StyledInputLabel>
                                            <StyledInputField style={{ textAlign: "center" }} name="value" type="password" />
                                            <ErrorMessage name="value" render={renderError} />
                                        </div>
                                        <div style={{ marginTop: "1rem" }}>
                                            <StyledButtonPrimaryWide variant="contained" type="submit">
                                                {resource[culture].save} <SaveIcon />
                                            </StyledButtonPrimaryWide>
                                        </div>
                                    </Form>
                                </Formik>
                            </Grid>
                        </Collapse>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={alertOpen}
                            autoHideDuration={6000}
                            onClose={() => setAlertOpen(false)}>
                            <Alert onClose={() => setAlertOpen(false)} severity="success" sx={{ width: "100%" }}>
                                {resource[culture].success}
                            </Alert>
                        </Snackbar>
                    </Card>
                </Grid>
            </Grid>
        </Fade>
    );
}
