export interface ILayoutResourceStrings {
    name: string;
    company: string;
    from: string;
    until: string;
    phoneNumber: string;
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings;
}

const engb = "en-GB";
const etee = "et-EE";
const ruru = "ru-RU";

export const VisitationHistoryTableHeaderTranslations: ILayoutResources = {
    [engb]: {
        name: "Name",
        company: "Company",
        from: "From",
        until: "Until",
        phoneNumber: "Phone number"
    },
    [ruru]: {
        name: "Имя",
        company: "Компания",
        from: "От",
        until: "До",
        phoneNumber: "Номер телефона"
    },
    [etee]: {
        name: "Nimi",
        company: "Ettevõte",
        from: "Alates",
        until: "Kuni",
        phoneNumber: "Tel. nr"
    }
};

export default VisitationHistoryTableHeaderTranslations;
