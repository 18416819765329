import React from "react";
import { Route } from "react-router-dom";
// TODO: make this work with TS
// eslint-disable-next-line
const PrivateRoute = ({ component, isAuthenticated, redirect, ...rest }: any) => {
    // eslint-disable-next-line
    const routeComponent = (props: any) => (isAuthenticated ? React.createElement(component, props) : redirect);
    return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
