import React from "react";

const Footer = (): React.ReactElement => {
    const date = new Date();
    return (
        <div className="footer-padding">
            <div className="footer">
                <hr />
                <p className="footer-text">Bellpepper OÜ | {date.getFullYear()}</p>
            </div>
        </div>
    );
};
export default Footer;
