import React, { useState } from "react";
import { AppContext, AppContextInitialState } from "./AppContext";
import ILoginResponse from "../model/ILoginResponse";
import { UserService } from "../services/UserService";
import { loadState, saveState } from "./LocalStorage";
import { DateIsMoreThan24HoursAgo } from "../utils/DateUtils";
import { getUserNameAndRoleFromJwt, IsJwtNotNullAndValid } from "../utils/JwtUtil";

interface IProps {
    children: React.ReactNode;
}

export default function AppContextProvider(props: IProps): JSX.Element {
    const [appState, setAppState] = useState(AppContextInitialState);

    const loadLocalStorage = () => {
        let jwtValid = false;
        const localState = loadState();
        if (localState !== null) {
            if (IsJwtNotNullAndValid(localState.jwt)) {
                jwtValid = true;
            }
            setAppState((appState) => ({
                ...appState,
                jwt: jwtValid ? localState.jwt : null,
                visitationId: localState.visitationId,
                selectedCulture: localState.selectedCulture,
                isAuthorizedGuest: DateIsMoreThan24HoursAgo(localState.guestAuthenticationTime) ? false : localState.isAuthorizedGuest,
                isRegisteredGuest: localState.isRegisteredGuest,
                username: localState.username,
                guestName: localState.guestName,
                guestAuthenticationTime: localState.guestAuthenticationTime,
                roleName: localState.roleName
            }));
        }
        return jwtValid;
    };
    const [isAuthenticated, setIsAuthenticated] = useState(() => loadLocalStorage());

    const login = (username: string, password: string): Promise<ILoginResponse | null> => {
        return UserService.login(username, password)
            .then((response) => {
                setIsAuthenticated(true);
                if (response?.accessToken) {
                    const jwtPayload = getUserNameAndRoleFromJwt(response.accessToken);
                    if (jwtPayload != null) {
                        setAppState({
                            ...appState,
                            jwt: response.accessToken,
                            username: jwtPayload!.username,
                            roleName: jwtPayload!.role
                        });
                        saveState({
                            ...appState,
                            jwt: response.accessToken,
                            username: jwtPayload!.username,
                            roleName: jwtPayload!.role
                        });
                    }
                }
                return response;
            })
            .catch((e) => {
                setIsAuthenticated(false);
                return Promise.reject(e);
            });
    };

    const setGuestNameAndVisitationId = (guestName: string, visitationId: number) => {
        setAppState({ ...appState, guestName, visitationId, isRegisteredGuest: true });
        saveState({ ...appState, guestName, visitationId, isRegisteredGuest: true });
    };

    const setJwt = (jwt: string) => {
        setAppState({ ...appState, jwt: jwt });
        saveState({ ...appState, jwt: jwt });
    };

    const setIsAuthorizedGuest = (isAuthorized: boolean) => {
        const authTime = new Date();
        setAppState((prevState) => ({ ...prevState, isAuthorizedGuest: isAuthorized, guestAuthenticationTime: authTime.toString() }));
        saveState({ ...appState, isAuthorizedGuest: isAuthorized, guestAuthenticationTime: authTime.toString() });
    };

    const setIsRegisteredGuest = (isRegistered: boolean) => {
        setAppState({ ...appState, isRegisteredGuest: isRegistered });
        saveState({ ...appState, isRegisteredGuest: isRegistered });
    };

    const setUserName = (username: string) => {
        setAppState({ ...appState, username });
    };

    const setAll = (
        jwt: string | null,
        userName: string,
        selectedCulture: string,
        isAuthorizedGuest: boolean,
        isRegisteredGuest: boolean,
        roleName: string | null,
        guestName: string | null,
        visitationId: number | null
    ) => {
        setAppState((prevState) => ({
            ...prevState,
            jwt: jwt,
            username: userName,
            selectedCulture: selectedCulture,
            isAuthorizedGuest: isAuthorizedGuest,
            isRegisteredGuest: isRegisteredGuest,
            roleName: roleName,
            guestName: guestName,
            visitationId: visitationId
        }));
        saveState({
            ...appState,
            jwt: jwt,
            username: userName,
            selectedCulture: selectedCulture,
            isAuthorizedGuest: isAuthorizedGuest,
            isRegisteredGuest: isRegisteredGuest,
            roleName: roleName,
            guestName: guestName,
            visitationId: visitationId
        });
    };

    const setCulture = (culture: string) => {
        const s = loadState();
        if (s !== null) {
            saveState({ ...s, selectedCulture: culture });
            setAppState((prev) => ({
                ...prev,
                jwt: s!.jwt,
                visitationId: s!.visitationId,
                selectedCulture: culture,
                isAuthorizedGuest: s!.isAuthorizedGuest,
                isRegisteredGuest: s!.isRegisteredGuest,
                username: s!.username,
                guestName: s!.guestName,
                roleName: s!.roleName
            }));
        } else {
            saveState({ ...AppContextInitialState, selectedCulture: culture });
            setAppState((prev) => ({ ...prev, selectedCulture: culture }));
        }
    };

    const setRoleName = (roleName: string) => {
        saveState({ ...AppContextInitialState, roleName: roleName });
        setAppState((prev) => ({ ...prev, roleName: roleName }));
    };

    const clearAll = () => {
        setAppState((prev) => ({ ...AppContextInitialState, selectedCulture: prev.selectedCulture, jwt: null, isAuthenticated: false }));
        saveState({ ...AppContextInitialState, selectedCulture: appState.selectedCulture, jwt: null });
        setIsAuthenticated(false);
    };

    return (
        <AppContext.Provider
            value={{
                jwt: appState.jwt,
                selectedCulture: appState.selectedCulture,
                visitationId: appState.visitationId,
                isRegisteredGuest: appState.isRegisteredGuest,
                isAuthorizedGuest: appState.isAuthorizedGuest,
                isAuthenticated: isAuthenticated,
                username: appState.username,
                guestName: appState.guestName,
                guestAuthenticationTime: appState.guestAuthenticationTime,
                roleName: appState.roleName,
                setJwt,
                setCulture,
                setGuestNameAndVisitationId,
                setIsAuthorizedGuest,
                setIsRegisteredGuest,
                setUserName,
                setAll,
                login,
                setRoleName,
                clearAll
            }}>
            {props.children}
        </AppContext.Provider>
    );
}
