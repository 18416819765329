import ILoginResponse from "../model/ILoginResponse";
import Axios from "axios";

export abstract class UserService {
    private static axios = Axios.create({
        baseURL: process.env.REACT_APP_URLPATH,
        headers: {
            "Content-Type": "Application/json"
        }
    });

    static async login(username: string, password: string): Promise<ILoginResponse | null> {
        const url = "login";
        const bodyFormData = new FormData();
        bodyFormData.append("username", username);
        bodyFormData.append("password", password);
        try {
            const response = await this.axios.post<ILoginResponse>(url, bodyFormData, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            });
            if (response.status === 200) {
                const accessToken = response.data.accessToken;
                const status = response.data.status;
                return { accessToken, status };
            }
            return null;
        } catch (error) {
            console.log("error", error);
            return null;
        }
    }
}
