import { Card, Divider, Fade, Grid, ListItem } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import GridItem from "../shared/GridItem";
import PersonIcon from "@mui/icons-material/Person";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LoginIcon from "@mui/icons-material/Login";
import EmailIcon from "@mui/icons-material/Email";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PhoneIcon from "@mui/icons-material/Phone";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import KeyIcon from "@mui/icons-material/Key";
import CommentIcon from "@mui/icons-material/Comment";
import { AppContext } from "../../../context/AppContext";
import CardHeader from "../../shared/CardHeader";
import { VisitationService } from "../../../services/VisitationService";
import { StyledButtonPrimaryWide } from "../shared/VisitationStyles";
import IVisitation from "../../../model/IVisitation";
import { makeStyles } from "@material-ui/core";
import VisitationStatusTranslations from "../../../translations/visitations/guestPages/VisitationStatus";
import { DateFormatter } from "../../../utils/DateUtils";
import EndVisitationDialog from "./EndVisitationDialog";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
    gridContainer: {
        padding: "0 2rem 1rem"
    },
    listItem: {
        padding: 0
    }
}));

export default function VisitationStatus(): React.ReactElement {
    const classes = useStyles();
    const history = useHistory();
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [visitation, setVisitation] = useState(null as IVisitation | null);
    const appContext = useContext(AppContext);
    const resource = VisitationStatusTranslations;
    const culture = appContext.selectedCulture;

    const endVisitation = async () => {
        const response = await VisitationService.endVisitation(visitation!.id, visitation!.name);
        if (response === 200) {
            setDialogOpen(false);
            appContext.clearAll();
            history.push("/visitation-finished");
        }
    };

    useEffect(() => {
        const callApi = async () => {
            const data = await VisitationService.getSingleVisitationDetails(appContext.visitationId!, appContext.guestName!);
            if (data !== null) {
                if (data.departed !== null) {
                    appContext.clearAll();
                } else {
                    setVisitation(data);
                }
            }
        };
        const toggleFade = () => {
            setIsDataLoaded(true);
        };
        callApi().then(() => toggleFade());
    }, [appContext.visitationId, appContext.guestName, appContext]);

    const renderVisitationDetails = () => {
        return (
            <>
                <CardHeader text={resource[culture].activeVisitation + ":"} />
                <Grid container alignItems="center" justifyContent="center" className={classes.gridContainer}>
                    <GridItem primaryText={resource[culture].name} secondaryText={visitation!.name} svgIcon={PersonIcon} />
                    <GridItem primaryText={resource[culture].phoneNumber} secondaryText={visitation!.phoneNumber} svgIcon={PhoneIcon} />
                    <GridItem
                        primaryText={resource[culture].companyName}
                        secondaryText={visitation!.companyName}
                        svgIcon={BusinessCenterIcon}
                    />
                    <GridItem
                        primaryText={resource[culture].visitationReason}
                        secondaryText={visitation!.visitationReason}
                        svgIcon={QuestionMarkIcon}
                    />
                    <GridItem
                        primaryText={resource[culture].vehicleRegistrationNumber}
                        secondaryText={visitation!.vehicleRegistrationNumber}
                        svgIcon={DirectionsCarIcon}
                    />
                    <GridItem
                        primaryText={resource[culture].from}
                        secondaryText={DateFormatter(new Date(visitation!.arrived))}
                        svgIcon={LoginIcon}
                    />
                    {visitation!.emailAddress === "" ? (
                        <></>
                    ) : (
                        <GridItem primaryText={resource[culture].email} secondaryText={visitation!.emailAddress} svgIcon={EmailIcon} />
                    )}
                    <GridItem
                        primaryText={resource[culture].hasKey}
                        secondaryText={visitation!.hasKey ? resource[culture].yes : resource[culture].no}
                        svgIcon={KeyIcon}
                    />
                    {visitation!.comment === "" ? (
                        <></>
                    ) : (
                        <GridItem primaryText={resource[culture].comment} secondaryText={visitation!.comment} svgIcon={CommentIcon} />
                    )}
                    <Divider />
                    <Grid container alignItems={"center"} justifyContent={"center"}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <ListItem className={classes.listItem}>
                                <StyledButtonPrimaryWide onClick={() => setDialogOpen(true)} variant="contained">
                                    {resource[culture].endVisitation}
                                </StyledButtonPrimaryWide>
                            </ListItem>
                        </Grid>
                    </Grid>
                </Grid>
                <EndVisitationDialog
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    visitationAction={endVisitation}
                    headerText={resource[culture].endVisitation}
                    contextText={resource[culture].contextText}
                    buttonText={resource[culture].buttonText}
                    cancelText={resource[culture].cancelText}
                    enableCheckbox={visitation!.hasKey}
                    keyText={resource[culture].keyText}
                />
            </>
        );
    };

    const renderErrorPage = () => {
        return (
            <>
                <CardHeader text={"Error"} />
                <Grid container alignItems="center" justifyContent="center" className={classes.gridContainer}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItem}>
                            <p>{resource[culture].notFound}</p>
                        </ListItem>
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <Fade in={isDataLoaded}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={11} sm={9} md={10} lg={8} xl={6}>
                    <Card>
                        {appContext.visitationId !== null && visitation !== null && appContext.guestName !== null
                            ? renderVisitationDetails()
                            : renderErrorPage()}
                    </Card>
                </Grid>
            </Grid>
        </Fade>
    );
}
