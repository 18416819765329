import { Card, Fade, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import VisitationEndedTranslations from "../../../translations/visitations/guestPages/VisitationEnded";

export default function VisitationEnded(): React.ReactElement {
    const appContext = useContext(AppContext);
    const resource = VisitationEndedTranslations;
    const culture = appContext.selectedCulture;
    return (
        <Fade in={true}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={10} sm={8} md={4} lg={4} xl={4}>
                    <Card style={{ marginTop: "4rem" }}>
                        <Grid container alignItems="center" flexDirection="column" justifyContent="center">
                            <Grid item style={{ padding: "1rem 2rem 0" }}>
                                <Typography
                                    variant="h6"
                                    component="h6"
                                    fontWeight={600}
                                    textAlign="center"
                                    style={{ marginBottom: "3rem" }}>
                                    {resource[culture].finished}
                                </Typography>
                            </Grid>
                            <Grid item style={{ padding: "0 2rem 2rem" }}>
                                <img
                                    style={{ display: "block", margin: " 0 auto", width: "10rem" }}
                                    src="images/checkmark.svg"
                                    alt="Checkmark for finished visitation"
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Fade>
    );
}
