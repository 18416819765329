import VisitationContextProvider from "../context/VisitationContextProvider";
import VisitationHistory from "./VisitationHistory";

export default function VisitationHistoryContextWrapper(): React.ReactElement {
    return (
        <VisitationContextProvider>
            <VisitationHistory />
        </VisitationContextProvider>
    );
}
