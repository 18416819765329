import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useContext, useState } from "react";
import IVisitation from "../../../model/IVisitation";
import { StyledTableCell, StyledTableRow } from "../shared/VisitationStyles";
import CollapsibleDetails from "../shared/CollapsibleDetails";
import { DateFormatter } from "../../../utils/DateUtils";
import { VisitationService } from "../../../services/VisitationService";
import { VisitationContext } from "../context/VisitationContext";
import VisitationActionDialog from "../shared/VisitationActionDialog";
import ActiveVisitationRowTranslations from "../../../translations/activeVisitation/ActiveVisitationRow";
import { AppContext } from "../../../context/AppContext";

interface IProps {
    i: number;
    row: IVisitation;
}

export default function ActiveVisitationRow(props: IProps): React.ReactElement<IProps> {
    const [collapsibleOpen, setCollapsibleOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const visitationContext = useContext(VisitationContext);
    const appContext = useContext(AppContext);
    const resource = ActiveVisitationRowTranslations;
    const culture = appContext.selectedCulture;

    const endVisitation = async () => {
        const response = await VisitationService.endVisitation(props.row.id, props.row.name);
        if (response === 200) {
            const visitationsList = visitationContext.visitations;
            setCollapsibleOpen((open) => !open);
            visitationsList.splice(props.i, 1);
            visitationContext.setVisitations(visitationsList);
        }
    };

    return (
        <>
            <StyledTableRow
                key={props.i}
                sx={{ "& > *": { borderBottom: "unset", border: 0, background: props.i % 2 === 0 ? "#efefef" : "#FCFCFC" } }}>
                <StyledTableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setCollapsibleOpen(!collapsibleOpen)}>
                        {collapsibleOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    {props.row.name}
                </StyledTableCell>
                <StyledTableCell>{props.row.companyName}</StyledTableCell>
                <StyledTableCell>{DateFormatter(new Date(props.row.arrived))}</StyledTableCell>
                <StyledTableCell>{props.row.phoneNumber}</StyledTableCell>
            </StyledTableRow>
            <CollapsibleDetails
                visitationAction={endVisitation}
                row={props.row}
                collapsibleOpen={collapsibleOpen}
                setCollapsibleOpen={setCollapsibleOpen}
                setDialogOpen={setDialogOpen}
                historyPage={false}
            />
            <VisitationActionDialog
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                visitationAction={endVisitation}
                headerText={resource[culture].endVisitation}
                contextText={resource[culture].contextText}
                buttonText={resource[culture].buttonText}
                cancelText={resource[culture].cancelText}
            />
        </>
    );
}
