export interface ILayoutResourceStrings {
    nameRequired: string;
    phoneNumberRequired: string;
    phoneNumberMatches: string;
    companyNameRequired: string;
    companyNameMin: string;
    companyNameMax: string;
    visitationReasonRequired: string;
    visitationReasonMin: string;
    visitationReasonMax: string;
    vehicleRegistrationNumberRequired: string;
    vehicleRegistrationNumberMatches: string;
    emailValidation: string;
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings;
}

const engb = "en-GB";
const etee = "et-EE";
const ruru = "ru-RU";

export const RegistrationValidationSchemaTranslations: ILayoutResources = {
    [engb]: {
        nameRequired: "Enter your full name",
        phoneNumberRequired: "Enter your phone number",
        phoneNumberMatches: "Phone number is invalid",
        companyNameRequired: "Enter company name",
        companyNameMin: "Company name must be at least 2 characters",
        companyNameMax: "Company name cannot be longer than 64 characters",
        visitationReasonRequired: "Enter visitation reason",
        visitationReasonMin: "Visitation reason must be atleast 3 characters",
        visitationReasonMax: "Company name cannot be longer than 128 characters",
        vehicleRegistrationNumberRequired: "Enter vehicle registration number",
        vehicleRegistrationNumberMatches: "Enter vehicle registration number",
        emailValidation: "E-mail address is invalid"
    },
    [ruru]: {
        nameRequired: "Введите свое полное имя",
        phoneNumberRequired: "Введите свой номер телефона",
        phoneNumberMatches: "Номер телефона недействителен",
        companyNameRequired: "Введите название компании",
        companyNameMin: "Название компании должно быть не менее 2 символов",
        companyNameMax: "Название компании не может быть длиннее 64 символов",
        visitationReasonRequired: "Введите причину посещения",
        visitationReasonMin: "Причина посещения должна быть не менее 3 символов",
        visitationReasonMax: "Название компании не может быть длиннее 128 символов",
        vehicleRegistrationNumberRequired: "Введите регистрационный номер автомобиля",
        vehicleRegistrationNumberMatches: "Введите регистрационный номер автомобиля",
        emailValidation: "Адрес е-мейл недействителен"
    },
    [etee]: {
        nameRequired: "Palun sisestage oma nimi",
        phoneNumberRequired: "Palun sisestage oma telefoninumber",
        phoneNumberMatches: "Palun sisestage oma telefoninumber",
        companyNameRequired: "Palun sisestage oma ettevõtte nimi",
        companyNameMin: "Ettevõtte nimi peab olema vähemalt 2 tähemärki pikk",
        companyNameMax: "Ettevõtte nimi ei tohi olla pikem kui 64 tähemärki",
        visitationReasonRequired: "Palun sisestage külastuse põhjus",
        visitationReasonMin: "Külastuspõhjus peab olema vähemalt 3 tähemärki pikk",
        visitationReasonMax: "Külastuspõhjus ei tohi olla pikem kui 128 tähemärki",
        vehicleRegistrationNumberRequired: "Palun sisestage oma sõiduki reg. number",
        vehicleRegistrationNumberMatches: "Sisestage oma sõiduki reg. number",
        emailValidation: "E-mail aadress ei ole kehtiv"
    }
};

export default RegistrationValidationSchemaTranslations;
