import { Avatar, Grid, ListItem, ListItemAvatar, ListItemText, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export interface IProps {
    primaryText: string;
    secondaryText: string | null;
    svgIcon: OverridableComponent<SvgIconTypeMap>;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
}

export default function GridItem(props: IProps): React.ReactElement<IProps> {
    return (
        <Grid item xs={props.xs ?? 12} sm={props.sm ?? 6} md={props.md ?? 4} lg={props.lg ?? 4} xl={props.xl ?? 3}>
            <ListItem alignItems="flex-start" style={{ padding: 0 }}>
                <ListItemAvatar>
                    <Avatar>
                        <props.svgIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={props.primaryText} secondary={props.secondaryText} />
            </ListItem>
        </Grid>
    );
}
