export interface ILayoutResourceStrings {
    header: string;
    name: string;
    namePlaceholder: string;
    phoneNumber: string;
    phoneNumberPlaceholder: string;
    companyName: string;
    companyNamePlaceholder: string;
    visitationReason: string;
    visitationReasonPlaceholder: string;
    vehicleRegistrationNumber: string;
    vehicleRegistrationNumberPlaceHolder: string;
    hasKey: string;
    email: string;
    emailPlaceholder: string;
    comment: string;
    commentPlaceholder: string;
    submit: string;
    accept: string;
    safetyBrief: string;
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings;
}

const engb = "en-GB";
const etee = "et-EE";
const ruru = "ru-RU";

export const VisitationRegistrationTranslations: ILayoutResources = {
    [engb]: {
        header: "Register a visitation",
        name: "Name",
        namePlaceholder: "Full name",
        phoneNumber: "Phone number",
        phoneNumberPlaceholder: "Enter phone number",
        companyName: "Company name",
        companyNamePlaceholder: "Enter name of your company",
        visitationReason: "Visitation reason",
        visitationReasonPlaceholder: "Enter visitation reason",
        vehicleRegistrationNumber: "Vehicle reg. number",
        vehicleRegistrationNumberPlaceHolder: "reg. number",
        hasKey: "Received key?",
        email: "E-mail",
        emailPlaceholder: "Enter e-mail",
        comment: "Comment",
        commentPlaceholder: "Key number",
        submit: "Register",
        accept: "I have read the ",
        safetyBrief: "safety brief"
    },
    [ruru]: {
        header: "Зарегистрируйте визит",
        name: "Имя",
        namePlaceholder: "Полное имя",
        phoneNumber: "Номер телефона",
        phoneNumberPlaceholder: "Введите номер телефона",
        companyName: "Компания",
        companyNamePlaceholder: "Введите название вашей компании",
        visitationReason: "Цель визита",
        visitationReasonPlaceholder: "Введите причину посещения",
        vehicleRegistrationNumber: "Регистрационный номер автомобиля",
        vehicleRegistrationNumberPlaceHolder: "Регистрационный номер",
        hasKey: "Ключ получен?",
        email: "е-мейл",
        emailPlaceholder: "Введите е-мейл",
        comment: "Комментарий",
        commentPlaceholder: "Номер ключа",
        submit: "зарегистрироваться",
        accept: "Я прочитал руководство по ",
        safetyBrief: "безопасности"
    },
    [etee]: {
        header: "Registreeri külastus",
        name: "Nimi",
        namePlaceholder: "Ees- ja perekonnanimi",
        phoneNumber: "Telefoni nr.",
        phoneNumberPlaceholder: "Sisesta telefoni nr.",
        companyName: "Ettevõtte nimi",
        companyNamePlaceholder: "Sisesta ettevõtte nimi",
        visitationReason: "Külastuse põhjus",
        visitationReasonPlaceholder: "Sisesta külastuse põhjus",
        vehicleRegistrationNumber: "Sõiduki reg. nr",
        vehicleRegistrationNumberPlaceHolder: "reg. nr",
        hasKey: "Sain võtme?",
        email: "E-mail",
        emailPlaceholder: "Sisesta e-mail",
        comment: "Kommentaar",
        commentPlaceholder: "Võtme nr.",
        submit: "Registreeri",
        accept: "Olen tutvunud ",
        safetyBrief: "ohutusjuhendiga"
    }
};

export default VisitationRegistrationTranslations;
