import "./App.css";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import AppContextProvider from "./context/AppContextProvider";
import Routing from "./components/shared/Routing";

export default function App(): React.ReactElement {
    const THEME = createTheme({
        typography: {
            fontFamily: `"Open Sans", sans-serif;`
        }
    });

    return (
        <MuiThemeProvider theme={THEME}>
            <AppContextProvider>
                <Routing />
            </AppContextProvider>
        </MuiThemeProvider>
    );
}
