export interface ILayoutResourceStrings {
    menu: string;
    activeVisitations: string;
    visitationHistory: string;
    addVisitation: string;
    accessKey: string;
    login: string;
    logout: string;
    visitationStatus: string;
    registerVisitation: string;
    language: string;
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings;
}

const engb = "en-GB";
const etee = "et-EE";
const ruru = "ru-RU";

export const NavbarTranslations: ILayoutResources = {
    [engb]: {
        menu: "Menu",
        activeVisitations: "Active visitations",
        visitationHistory: "Visitation history",
        addVisitation: "Add visitation",
        accessKey: "Guest password",
        login: "Log in",
        logout: "Log out",
        visitationStatus: "Visitation status",
        registerVisitation: "Register visitation",
        language: "Language"
    },
    [ruru]: {
        menu: "Меню",
        activeVisitations: "Активные посещения",
        visitationHistory: "История посещений",
        addVisitation: "Добавить посещение",
        accessKey: "Гостевой пароль",
        login: "Регистрировать",
        logout: "Выйти",
        visitationStatus: "Статус посещения",
        registerVisitation: "Регистрация посещения",
        language: "Язык"
    },
    [etee]: {
        menu: "Menüü",
        activeVisitations: "Aktiivsed külastused",
        visitationHistory: "Külastuste ajalugu",
        addVisitation: "Lisa külastus",
        accessKey: "Külaliste parool",
        login: "Logi sisse",
        logout: "Logi välja",
        visitationStatus: "Külastuse staatus",
        registerVisitation: "Külastuse registreerimine",
        language: "Keel"
    }
};

export default NavbarTranslations;
