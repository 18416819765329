import Axios from "axios";
import IAccessKey from "../model/IAccessKey";
import IAccessKeyValidationDTO from "../model/IAccessKeyValidationDTO";

export abstract class AccessKeyService {
    private static axios = Axios.create({
        baseURL: process.env.REACT_APP_URLPATH + "key",
        headers: {
            "Content-Type": "Application/json"
        }
    });

    static async getAccessKey(jwt: string): Promise<IAccessKey | null> {
        const url = "";
        try {
            const response = await this.axios.get<IAccessKey>(url, {
                headers: {
                    Authorization: "Bearer " + jwt
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            return null;
        } catch (error) {
            console.log("error", error);
            return null;
        }
    }

    static async updateAccessKey(jwt: string, accessKey: IAccessKey): Promise<number> {
        const url = "";
        try {
            const response = await this.axios.put<IAccessKey>(url, accessKey, {
                headers: {
                    Authorization: "Bearer " + jwt
                }
            });
            if (response.status === 200) {
                return response.status;
            }
            return response.status;
        } catch (error) {
            console.log("error", error);
        }
        return 400;
    }

    static async validateAccessKey(accessKey: IAccessKeyValidationDTO): Promise<number> {
        const url = "/authenticate";
        try {
            const response = await this.axios.post<IAccessKeyValidationDTO>(url, accessKey);
            if (response.status === 200) {
                return response.status;
            }
            return response.status;
        } catch (error) {
            console.log("error", error);
        }
        return 400;
    }
}
