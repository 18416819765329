import { Box, Pagination, Table, TableBody, TableCell, TableContainer, TableHeadTypeMap, TableRow } from "@mui/material";
import IVisitation from "../../../model/IVisitation";
import ActiveVisitationRow from "../activeVisitation/ActiveVisitationRow";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import VisitationHistoryRow from "../visitationHistory/VisitationHistoryRow";
import { VisitationContext } from "../context/VisitationContext";
import { ChangeEvent, useContext } from "react";

export interface IProps {
    tableHeader: OverridableComponent<TableHeadTypeMap>;
    activeRow: boolean;
}

export default function CollapsibleTable(props: IProps): React.ReactElement<IProps> {
    const visitationContext = useContext(VisitationContext);
    const renderHistoryRows = () => {
        return (
            <>
                {visitationContext.visitationPage?.content.map((rowData: IVisitation, index: number) => (
                    <VisitationHistoryRow key={index} i={index} row={rowData} />
                ))}
            </>
        );
    };

    const renderActiveRows = () => {
        return (
            <>
                {visitationContext.visitations.map((rowData: IVisitation, index: number) => (
                    <ActiveVisitationRow key={index} i={index} row={rowData} />
                ))}
            </>
        );
    };

    const setPageNum = (pageNumber: number) => {
        const visitationPage = visitationContext.visitationPage;
        visitationPage!.number = pageNumber;
        visitationContext.setVisitationPage(visitationPage!);
    };

    const emptyRows = visitationContext.visitationPage!.size - visitationContext.visitationPage!.numberOfElements;

    const handleChangePage = (event: ChangeEvent<unknown>, newPage: number) => {
        if (visitationContext.visitationPage?.number !== newPage - 1) {
            setPageNum(newPage - 1);
        }
    };

    return (
        <TableContainer>
            <Table aria-label="collapsible table">
                <props.tableHeader />
                <TableBody>
                    {props.activeRow ? renderActiveRows() : renderHistoryRows()}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 66 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {!props.activeRow ? (
                <Box>
                    <Pagination
                        size="large"
                        style={{ padding: "1rem 0rem" }}
                        onChange={(e, page) => handleChangePage(e, page)}
                        count={visitationContext.visitationPage?.totalPages}
                        shape="rounded"
                    />
                </Box>
            ) : (
                <></>
            )}
        </TableContainer>
    );
}
