import { Card, Divider, Grid, makeStyles } from "@material-ui/core";
import LoginIcon from "@mui/icons-material/Login";
import { FormEvent, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { useHistory } from "react-router-dom";
import CardHeader from "./CardHeader";
import LoginTranslations from "../../translations/shared/Login";
import { StyledButtonPrimaryWide, StyledInputLabel } from "../visitations/shared/VisitationStyles";

const useStyles = makeStyles(() => ({
    form: {
        paddingTop: "10px"
    },
    label: {
        display: "block",
        fontWeight: "bold",
        padding: "10px 0px"
    },
    inputField: {
        width: "100%",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid #BFC0C0",
        borderRadius: "3px",
        fontSize: "1rem",
        fontWeight: 300,
        outlineColor: "DBF3DB",

        "&:focus": {
            outlineColor: "DBF3DB"
        }
    },
    fieldWrapper: {
        padding: "0px 1rem"
    },
    buttonWrapper: {
        padding: "1rem 1rem"
    },
    validationText: {
        fontSize: 14,
        color: "#EF8354"
    }
}));

interface ILoginState {
    username: string;
    password: string;
    usernameError: string;
    passwordError: string;
}

const initialState = {
    username: "",
    password: "",
    usernameError: "",
    passwordError: ""
};

export default function Login(): React.ReactElement {
    const classes = useStyles();
    const [state, setState] = useState(initialState as ILoginState);
    const appContext = useContext(AppContext);
    const resource = LoginTranslations;
    const culture = appContext.selectedCulture;

    const history = useHistory();

    const handleChange = (target: (EventTarget & HTMLInputElement) | (EventTarget & HTMLTextAreaElement)) => {
        if (target.type === "text" && target.name === "username") {
            setState({ ...state, [target.name]: target.value, usernameError: "" });
        }
        if (target.type === "password") {
            setState({ ...state, [target.name]: target.value, passwordError: "" });
        }
    };

    const validate = () => {
        if (state.username === "") {
            setState({ ...state, usernameError: resource[culture].usernameError });
            return false;
        }
        if (state.password === "") {
            setState({ ...state, passwordError: resource[culture].passwordAndUserDontMatch });
            return false;
        }
        return true;
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const isValid = validate();
        if (isValid) {
            const response = await appContext.login(state.username, state.password);
            if (response != null) {
                if (response.accessToken != null) {
                    setState(initialState);
                    history.push("/active-visitations");
                }
            } else {
                setState({ ...state, passwordError: resource[culture].passwordAndUserDontMatch });
            }
        }
    };

    const renderLoggedIn = () => {
        return <CardHeader text={resource[culture].alreadyLoggedIn} />;
    };

    const renderLogin = () => {
        return (
            <>
                <CardHeader text={resource[culture].login} />
                <Divider variant={"middle"} />

                <form className={classes.form} onSubmit={handleSubmit}>
                    <div className={classes.fieldWrapper}>
                        <StyledInputLabel>{resource[culture].userName}</StyledInputLabel>
                        <input
                            className={classes.inputField}
                            name="username"
                            type="text"
                            value={state.username}
                            onChange={(e) => handleChange(e.target)}
                        />
                        <div className={classes.validationText}>{state.usernameError}</div>
                    </div>

                    <div className={classes.fieldWrapper}>
                        <StyledInputLabel>{resource[culture].password}</StyledInputLabel>
                        <input
                            className={classes.inputField}
                            name="password"
                            type="password"
                            value={state.password}
                            onChange={(e) => handleChange(e.target)}
                        />
                        <div className={classes.validationText}>{state.passwordError}</div>
                    </div>

                    <div className={classes.buttonWrapper}>
                        <StyledButtonPrimaryWide variant="contained" type="submit">
                            {resource[culture].login} <LoginIcon />
                        </StyledButtonPrimaryWide>
                    </div>
                </form>
            </>
        );
    };

    return (
        <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={4} lg={4} xl={2}>
                <Card>{appContext.jwt !== null && appContext.jwt !== "" ? renderLoggedIn() : renderLogin()}</Card>
            </Grid>
        </Grid>
    );
}
