import { Grid, Typography } from "@material-ui/core";
import CardHeader from "../../shared/CardHeader";
import { Divider, Fade } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { VisitationService } from "../../../services/VisitationService";
import { AppContext } from "../../../context/AppContext";
import CollapsibleTable from "../shared/CollapsibleTable";
import Clock from "../../shared/Clock";
import ActiveVisitationTableHeader from "./ActiveVisitationTableHeader";
import { VisitationContext } from "../context/VisitationContext";
import { StyledCard, StyledTableBox } from "../shared/VisitationStyles";
import VisitationPlaceholder from "../shared/VisitationPlaceholder";
import ActiveVisitationsTranslations from "../../../translations/activeVisitation/ActiveVisitations";

export default function ActiveVisitations(): React.ReactElement {
    const appContext = useContext(AppContext);
    const resource = ActiveVisitationsTranslations;
    const culture = appContext.selectedCulture;
    const { setVisitations, visitations } = useContext(VisitationContext);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        const callApiOnce = async () => {
            const visits = await VisitationService.getActiveVisitations(appContext.jwt!);
            setVisitations(visits);
        };
        callApiOnce().then(() => setIsDataLoaded(true));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const callApi = setInterval(async () => {
            const activeVisitations = await VisitationService.getActiveVisitations(appContext.jwt!);
            setVisitations(activeVisitations);
        }, 60000);
        return () => clearInterval(callApi);
    });

    return (
        <Fade in={isDataLoaded}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12} lg={8} xl={8}>
                    <StyledCard>
                        <CardHeader text={resource[culture].activeVisitations} />
                        <Typography style={{ padding: "0px 1rem 1rem" }} component="h5" variant="h5">
                            <Clock />
                        </Typography>
                        <Divider variant={"middle"} />
                        <StyledTableBox>
                            {visitations.length > 0 ? (
                                <CollapsibleTable activeRow={true} tableHeader={ActiveVisitationTableHeader} />
                            ) : (
                                <VisitationPlaceholder
                                    headerText={resource[culture].placeholderHeader}
                                    fillerText={resource[culture].placeholderBody}
                                    boldText={resource[culture].placeholderBold}
                                    fillerTextSecondary={resource[culture].placeholderBodySecond}
                                />
                            )}
                        </StyledTableBox>
                    </StyledCard>
                </Grid>
            </Grid>
        </Fade>
    );
}
