export interface ILayoutResourceStrings {
    finished: string;
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings;
}

const engb = "en-GB";
const etee = "et-EE";
const ruru = "ru-RU";

export const VisitationEndedTranslations: ILayoutResources = {
    [engb]: {
        finished: "Visitation successfully finished!"
    },
    [ruru]: {
        finished: "Посещение успешно завершено!"
    },
    [etee]: {
        finished: "Külastus edukalt lõpetatud!"
    }
};

export default VisitationEndedTranslations;
