import jwt_decode, { JwtPayload } from "jwt-decode";
import CustomJwtPayload from "../model/CustomJwtPayload";
import { GetCurrentDateEpochSeconds } from "./DateUtils";

interface JwtPayloadHelper {
    sub: string;
    role: string[];
}

export const parseJwt = (jwt: string): CustomJwtPayload => {
    const base64Url = jwt.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );
    const payload: JwtPayloadHelper = JSON.parse(jsonPayload);
    const customPayload: CustomJwtPayload = {
        username: payload.sub,
        role: payload.role[0].toUpperCase()
    };
    return customPayload;
};

export const IsJwtNotNullAndValid = (jwt: string | null): boolean => {
    if (jwt === null || jwt === "") return false;
    const decodedJwt: JwtPayload = jwt_decode(jwt);
    if (GetCurrentDateEpochSeconds() >= decodedJwt.exp!) {
        return false;
    }
    return true;
};

export const getUserNameAndRoleFromJwt = (jwt: string | null): CustomJwtPayload | null => {
    if (jwt === null || jwt === "") return null;
    const decodedJwt: CustomJwtPayload = parseJwt(jwt);
    return decodedJwt;
};
