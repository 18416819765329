export interface ILayoutResourceStrings {
    login: string;
    userName: string;
    password: string;
    alreadyLoggedIn: string;
    passwordAndUserDontMatch: string;
    usernameError: string;
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings;
}

const engb = "en-GB";
const etee = "et-EE";
const ruru = "ru-RU";

export const LoginTranslations: ILayoutResources = {
    [engb]: {
        login: "Log in",
        userName: "Username",
        password: "Password",
        alreadyLoggedIn: "You are already logged in!",
        passwordAndUserDontMatch: "Password and username don't match",
        usernameError: "Username is required"
    },
    [ruru]: {
        login: "регистрировать",
        userName: "Имя пользователя",
        password: "Пароль",
        alreadyLoggedIn: "Вы уже вошли в систему!",
        passwordAndUserDontMatch: "Пароль и имя пользователя не совпадают",
        usernameError: "Имя пользователя требуется"
    },
    [etee]: {
        login: "Logi sisse",
        userName: "Kasutajanimi",
        password: "Parool",
        alreadyLoggedIn: "Oled juba sisse logitud!",
        passwordAndUserDontMatch: "Kasutajanimi ja parool ei ühti",
        usernameError: "Kasutajanimi ei saa olla tühi"
    }
};

export default LoginTranslations;
