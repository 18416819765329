import { Collapse, Fade, Grid } from "@mui/material";
import { Divider, makeStyles } from "@material-ui/core";
import LoginIcon from "@mui/icons-material/Login";
import { Formik, Form, ErrorMessage } from "formik";
import { CheckboxField } from "./CheckboxField";
import Checkbox from "@material-ui/core/Checkbox";
import {
    StyledButtonPrimaryWide,
    StyledCard,
    StyledInlineCheckboxLabel,
    StyledInlineInputLabelRequired,
    StyledInputField,
    StyledInputLabel,
    StyledInputLabelRequired
} from "../shared/VisitationStyles";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { VisitationService } from "../../../services/VisitationService";
import IVisitationPostDTO from "../../../model/IVisitationPostDTO";
import VisitationRegistrationTranslations from "../../../translations/visitations/registration/VisitationRegistration";
import * as Yup from "yup";
import RegistrationValidationSchemaTranslations from "../../../translations/visitations/registration/RegistrationValidationSchema";
import { DateIsMoreThan24HoursAgo } from "../../../utils/DateUtils";
import CardHeader from "../../shared/CardHeader";

interface IRegistrationState {
    name: string;
    phoneNumber: string;
    companyName: string;
    visitationReason: string;
    vehicleRegistrationNumber: string;
    hasKey: boolean;
    acceptSafetyBrief: boolean;
    emailAddress: string;
    comment: string;
}

const initialState = {
    name: "",
    phoneNumber: "",
    companyName: "",
    visitationReason: "",
    vehicleRegistrationNumber: "",
    hasKey: false,
    emailAddress: "",
    comment: ""
};

const useStyles = makeStyles(() => ({
    form: {
        paddingTop: "0.5rem"
    },
    fieldWrapper: {
        padding: "0px 1rem"
    },
    inlineFieldLeft: {
        boxSizing: "border-box",
        float: "left",
        width: "50%",
        paddingRight: "4px",
        paddingLeft: "1rem"
    },
    inlineFieldRight: {
        boxSizing: "border-box",
        float: "left",
        width: "50%",
        paddingRight: "1rem",
        paddingLeft: "4px"
    },
    buttonWrapper: {
        padding: "1rem 1rem"
    },
    validationText: {
        fontSize: 14,
        color: "#EF8354"
    },
    inlineLabelContainer: {
        display: "flex"
    },
    inlineLabelWrapper: {
        verticalAlign: "bottom",
        display: "flex"
    },
    inlineInputContainer: {
        overflow: "hidden"
    }
}));

export default function VisitationRegistration(): React.ReactElement {
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const history = useHistory();
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);
    const resource = VisitationRegistrationTranslations;
    const validationResource = RegistrationValidationSchemaTranslations;
    const culture = appContext.selectedCulture;

    const safetyBriefPath = (): string => {
        if (culture === "et-EE") {
            return "safetyBriefs/SafetyBriefEST.pdf";
        } else if (culture === "ru-RU") {
            return "safetyBriefs/SafetyBriefRUS.pdf";
        } else {
            return "safetyBriefs/SafetyBriefENG.pdf";
        }
    };

    const RegistrationValidationSchema = Yup.object({
        name: Yup.string().required(validationResource[culture].nameRequired),
        phoneNumber: Yup.string()
            .required(validationResource[culture].phoneNumberRequired)
            .matches(
                new RegExp("^(\\+?\\d{0,4})?\\s?-?\\s?(\\(?\\d{3}\\)?)\\s?-?\\s?(\\(?\\d{3}\\)?)\\s?-?\\s?(\\(?\\d{4}\\)?)?$"),
                validationResource[culture].phoneNumberMatches
            ),
        companyName: Yup.string()
            .required(validationResource[culture].companyNameRequired)
            .min(2, validationResource[culture].companyNameMin)
            .max(64, validationResource[culture].companyNameMax),
        visitationReason: Yup.string()
            .required(validationResource[culture].visitationReasonRequired)
            .min(3, validationResource[culture].visitationReasonMin)
            .max(128, validationResource[culture].visitationReasonMax),
        vehicleRegistrationNumber: Yup.string()
            .required(validationResource[culture].vehicleRegistrationNumberRequired)
            .min(1, validationResource[culture].vehicleRegistrationNumberMatches)
            .max(20, validationResource[culture].vehicleRegistrationNumberMatches),
        hasKey: Yup.boolean().default(false),
        acceptSafetyBrief: Yup.boolean().default(false),
        emailAddress: Yup.string().optional().email(validationResource[culture].emailValidation),
        comment: Yup.string().optional()
    });

    useEffect(() => {
        const toggleFade = () => {
            setIsDataLoaded(true);
        };
        toggleFade();
    }, []);

    const renderError = (message: string) => <div className={classes.validationText}>{message}</div>;

    const handleSubmit = async (values: IRegistrationState) => {
        const response = await VisitationService.postNewVisitation(values as IVisitationPostDTO);
        if (response != null) {
            if (appContext.jwt !== null && appContext.jwt !== "") {
                history.push("/active-visitations");
            } else if (appContext.isAuthorizedGuest && !DateIsMoreThan24HoursAgo(appContext.guestAuthenticationTime)) {
                appContext.setGuestNameAndVisitationId(response.name, response.id);
                history.push("/");
            }
        }
    };

    const checkAcceptedBox = () => {
        setIsAccepted((prev) => !prev);
    };

    const errorBorder = "1px solid #ff986c";

    return (
        <Fade in={isDataLoaded}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={8} md={5} lg={4} xl={3}>
                    <StyledCard>
                        <CardHeader text={resource[culture].header} />
                        <Divider variant={"middle"} />
                        <Formik
                            initialValues={initialState as IRegistrationState}
                            validationSchema={RegistrationValidationSchema}
                            onSubmit={(values, { resetForm }) => handleSubmit(values).then(() => resetForm())}>
                            {({ values, touched, errors }) => (
                                <Form className={classes.form}>
                                    <div className={classes.fieldWrapper}>
                                        <StyledInputLabelRequired>{resource[culture].name}</StyledInputLabelRequired>
                                        <StyledInputField
                                            name="name"
                                            type="text"
                                            sx={{ border: touched.name && errors.name ? errorBorder : "" }}
                                            placeholder={resource[culture].namePlaceholder}
                                        />
                                        <ErrorMessage name="name" render={renderError} />
                                    </div>

                                    <div className={classes.inlineLabelContainer}>
                                        <div className={`${classes.inlineLabelWrapper} ${classes.inlineFieldLeft}`}>
                                            <StyledInlineInputLabelRequired>{resource[culture].phoneNumber}</StyledInlineInputLabelRequired>
                                        </div>
                                        <div className={`${classes.inlineLabelWrapper} ${classes.inlineFieldRight}`}>
                                            <StyledInlineInputLabelRequired>
                                                {resource[culture].vehicleRegistrationNumber}
                                            </StyledInlineInputLabelRequired>
                                        </div>
                                    </div>

                                    <div className={classes.inlineInputContainer}>
                                        <div className={classes.inlineFieldLeft}>
                                            <StyledInputField
                                                name="phoneNumber"
                                                type="text"
                                                sx={{ border: touched.phoneNumber && errors.phoneNumber ? errorBorder : "" }}
                                                placeholder={resource[culture].phoneNumberPlaceholder}
                                            />
                                            <ErrorMessage name="phoneNumber" render={renderError} />
                                        </div>
                                        <div className={classes.inlineFieldRight}>
                                            <StyledInputField
                                                name="vehicleRegistrationNumber"
                                                type="text"
                                                sx={{
                                                    border:
                                                        touched.vehicleRegistrationNumber && errors.vehicleRegistrationNumber
                                                            ? errorBorder
                                                            : ""
                                                }}
                                                placeholder={resource[culture].vehicleRegistrationNumberPlaceHolder}
                                            />
                                            <ErrorMessage name="vehicleRegistrationNumber" render={renderError} />
                                        </div>
                                    </div>

                                    <div className={classes.fieldWrapper}>
                                        <StyledInputLabelRequired>{resource[culture].companyName}</StyledInputLabelRequired>
                                        <StyledInputField
                                            name="companyName"
                                            type="text"
                                            sx={{ border: touched.companyName && errors.companyName ? errorBorder : "" }}
                                            placeholder={resource[culture].companyNamePlaceholder}
                                        />
                                        <ErrorMessage name="companyName" render={renderError} />
                                    </div>

                                    <div className={classes.fieldWrapper}>
                                        <StyledInputLabelRequired>{resource[culture].visitationReason}</StyledInputLabelRequired>
                                        <StyledInputField
                                            name="visitationReason"
                                            type="text"
                                            sx={{ border: touched.visitationReason && errors.visitationReason ? errorBorder : "" }}
                                            placeholder={resource[culture].visitationReasonPlaceholder}
                                        />
                                        <ErrorMessage name="visitationReason" render={renderError} />
                                    </div>

                                    <div className={classes.fieldWrapper}>
                                        <StyledInputLabel>{resource[culture].email}</StyledInputLabel>
                                        <StyledInputField
                                            name="emailAddress"
                                            type="text"
                                            sx={{ border: touched.emailAddress && errors.emailAddress ? errorBorder : "" }}
                                            placeholder={resource[culture].emailPlaceholder}
                                        />
                                        <ErrorMessage name="emailAddress" render={renderError} />
                                    </div>

                                    <div className={classes.fieldWrapper}>
                                        <StyledInlineCheckboxLabel>{resource[culture].hasKey}</StyledInlineCheckboxLabel>
                                        <CheckboxField name="hasKey" />
                                        <ErrorMessage name="hasKey" render={renderError} />
                                    </div>
                                    <Collapse in={values.hasKey} timeout="auto" unmountOnExit>
                                        <div className={classes.fieldWrapper} style={{ paddingBottom: 0 }}>
                                            <StyledInputField
                                                name="comment"
                                                type="text"
                                                placeholder={resource[culture].commentPlaceholder}
                                            />
                                            <ErrorMessage name="comment" render={renderError} />
                                        </div>
                                    </Collapse>

                                    <div className={classes.fieldWrapper}>
                                        <StyledInlineCheckboxLabel>
                                            {resource[culture].accept}
                                            <a
                                                style={{ color: "#339cdb" }}
                                                href={safetyBriefPath()}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                {resource[culture].safetyBrief}
                                            </a>
                                        </StyledInlineCheckboxLabel>
                                        <Checkbox size="medium" onChange={() => checkAcceptedBox()} checked={isAccepted} color="default" />
                                        <ErrorMessage name="acceptSafetyBrief" render={renderError} />
                                    </div>

                                    <div className={classes.buttonWrapper}>
                                        <StyledButtonPrimaryWide disabled={!isAccepted} variant="contained" type="submit">
                                            {resource[culture].submit} <LoginIcon />
                                        </StyledButtonPrimaryWide>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </StyledCard>
                </Grid>
            </Grid>
        </Fade>
    );
}
