import { TableHead, TableRow } from "@mui/material";
import { StyledTableCell } from "../shared/VisitationStyles";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import VisitationHistoryTableHeaderTranslations from "../../../translations/visitationHistory/VIsitationHistoryTableHeader";

export default function VisitationHistoryTableHeader(): React.ReactElement {
    const appContext = useContext(AppContext);
    const resource = VisitationHistoryTableHeaderTranslations;
    const culture = appContext.selectedCulture;

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell />
                <StyledTableCell>{resource[culture].name}</StyledTableCell>
                <StyledTableCell>{resource[culture].company}</StyledTableCell>
                <StyledTableCell>{resource[culture].from}</StyledTableCell>
                <StyledTableCell>{resource[culture].until}</StyledTableCell>
                <StyledTableCell>{resource[culture].phoneNumber}</StyledTableCell>
            </TableRow>
        </TableHead>
    );
}
