import Checkbox from "@material-ui/core/Checkbox";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core";
import { ChangeEvent } from "react";

export interface CheckboxFieldProps {
    name: string;
}

const useStyles = makeStyles(() => ({
    checkbox: {
        verticalAlign: "middle",
        padding: "10px",
        width: "2rem",
        height: "2rem",
        borderRadius: "5px",
        "&:checked": {
            color: "red"
        }
    }
}));

/**
 * Formik field to edit a boolean
 * Field value type: boolean
 */
export const CheckboxField = ({ name }: CheckboxFieldProps): React.ReactElement<CheckboxFieldProps> => {
    const [field, , helpers] = useField(name);
    const classes = useStyles();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(event.target.checked);
    };
    return (
        <Checkbox className={classes.checkbox} name="hasKey" size="medium" onChange={handleChange} checked={field.value} color="default" />
    );
};
