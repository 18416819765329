import { TableHead, TableRow } from "@mui/material";
import { StyledTableCell } from "../shared/VisitationStyles";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { ActiveVisitationTableHeaderTranslations } from "../../../translations/activeVisitation/ActiveVisitationTableHeader";

export default function ActiveVisitationTableHeader(): React.ReactElement {
    const appContext = useContext(AppContext);
    const resource = ActiveVisitationTableHeaderTranslations;
    const culture = appContext.selectedCulture;

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell />
                <StyledTableCell>{resource[culture].name}</StyledTableCell>
                <StyledTableCell>{resource[culture].company}</StyledTableCell>
                <StyledTableCell>{resource[culture].from}</StyledTableCell>
                <StyledTableCell>{resource[culture].phoneNumber}</StyledTableCell>
            </TableRow>
        </TableHead>
    );
}
