import { Button, Link, makeStyles, MenuItem } from "@material-ui/core";
import { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { Menu } from "@mui/material";
import { INavbarLink } from "../../../model/INavbarLink";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
    image: {
        padding: "0.65rem"
    },
    div: {
        marginTop: "auto",
        marginBottom: "auto",
        alignItems: "center"
    }
}));

interface IProps {
    navLinks: INavbarLink[];
}

export default function UserMenu(props: IProps): React.ReactElement {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.div}>
            <Button
                style={{ color: "#FFF", textTransform: "capitalize" }}
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}>
                <PersonIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ "aria-labelledby": "basic-button" }}>
                {props.navLinks.map((link) => (
                    <Link
                        key={link.key}
                        {...{
                            component: NavLink,
                            to: link.to,
                            color: "inherit",
                            style: { textDecoration: "none" }
                        }}>
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                link.onClick();
                            }}
                            className={classes.image}>
                            {link.icon ?? link.icon}
                            {link.buttonTitle}
                        </MenuItem>
                    </Link>
                ))}
            </Menu>
        </div>
    );
}
