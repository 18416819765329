import { AppBar, Divider, makeStyles, MenuItem, Toolbar } from "@material-ui/core";
import { NavLink, useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import { Box, Drawer, IconButton, Link } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LanguagePicker from "./LanguagePicker";
import NavbarTranslations from "../../../translations/shared/Navbar";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { INavbarLink } from "../../../model/INavbarLink";
import UserMenu from "./UserMenu";

const useStyles = makeStyles(() => ({
    header: {
        backgroundColor: "#2D3142",
        color: "#FFF",
        display: "flex",
        "@media (max-width: 1050px)": {
            alignItems: "flex-start"
        }
    },
    navLink: {
        color: "#FFF",
        borderBottom: "2px solid #2D3142",
        "&:hover": {
            borderBottom: "2px solid #BFC0C0",
            transition: "all 0.35s ease-in-out"
        },
        "@media (max-width: 1050px)": {
            padding: "unset",
            borderBottom: "none"
        }
    },
    menuItem: {
        padding: "1rem 1rem"
    },
    navLinkActive: {
        borderBottom: "2px solid #BFC0C0"
    },
    toolbar: {
        justifyContent: "space-between"
    }
}));

export default function Navbar(): React.ReactElement {
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const history = useHistory();
    const resource = NavbarTranslations;
    const culture = appContext.selectedCulture;
    const [state, setState] = useState({ mobileView: false, drawerOpen: false });

    const roles = {
        employee: "EMPLOYEE",
        view_employee: "VIEW_EMPLOYEE",
        admin: "ADMIN"
    };

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 1050
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => window.removeEventListener("resize", () => setResponsiveness());
    }, [appContext.selectedCulture]);

    const handleDrawerOpen = () => setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () => setState((prevState) => ({ ...prevState, drawerOpen: false }));

    const handleLogOut = () => {
        appContext.clearAll();
        history.push("/login");
        setState((prevState) => ({ ...prevState, drawerOpen: false }));
    };

    const loggedInNavLinks: INavbarLink[] = [
        {
            to: "/active-visitations",
            key: "active-visitations",
            buttonTitle: resource[culture].activeVisitations,
            roles: [roles.admin, roles.employee, roles.view_employee],
            onClick: handleDrawerClose
        },
        {
            to: "/visitation-history",
            key: "visitation-history",
            buttonTitle: resource[culture].visitationHistory,
            roles: [roles.admin, roles.employee, roles.view_employee],
            onClick: handleDrawerClose
        },
        {
            to: "/register",
            key: "register",
            buttonTitle: resource[culture].addVisitation,
            roles: [roles.admin, roles.employee],
            onClick: handleDrawerClose
        },
        {
            to: "/access-key",
            key: "access-key",
            buttonTitle: resource[culture].accessKey,
            roles: [roles.admin, roles.employee],
            onClick: handleDrawerClose
        }
    ];

    const userLinks: INavbarLink[] = [
        {
            to: "/login",
            key: "logout",
            buttonTitle: appContext.jwt ? resource[culture].logout : resource[culture].login,
            roles: [roles.admin, roles.employee, roles.view_employee],
            onClick: handleLogOut,
            icon: appContext.jwt ? <LogoutIcon /> : <LoginIcon />
        }
    ];

    const guestNavLinks: INavbarLink[] = [
        {
            to: "/authorization",
            key: "authorization",
            buttonTitle: appContext.isRegisteredGuest ? resource[culture].visitationStatus : resource[culture].registerVisitation,
            roles: [],
            onClick: handleDrawerClose
        }
    ];

    const displayDesktop = () => {
        return (
            <>
                <Box sx={{ display: "flex" }}>
                    <img src="icon.png" alt="logo" style={{ filter: "invert(1)", height: "2rem", width: "2rem", paddingRight: "6rem" }} />
                </Box>
                <Box sx={{ display: "flex" }}>
                    {appContext.jwt !== null && appContext.jwt !== "" ? renderLoggedInNavLinks() : renderDefaultNavLinks()}
                </Box>
                <Box sx={{ display: "flex" }}>
                    <LanguagePicker title={resource[culture].language} />
                    <UserMenu navLinks={userLinks} />
                </Box>
            </>
        );
    };

    const displayMobile = () => {
        return (
            <>
                <IconButton
                    className={classes.navLink}
                    {...{
                        color: "inherit",
                        style: { border: "none" },
                        edge: "start",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                        onClick: handleDrawerOpen
                    }}>
                    <MenuIcon />
                </IconButton>
                <Drawer {...{ anchor: "left", open: state.drawerOpen, onClose: handleDrawerClose }}>
                    {state.mobileView ? (
                        <MenuItem
                            disabled={true}
                            style={{ fontWeight: "bold", color: "#000", opacity: "unset", padding: "1rem 1rem 1rem" }}>
                            {resource[culture].menu}
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    <Divider />
                    <div>
                        {appContext.jwt !== null && appContext.jwt !== "" ? <>{renderLoggedInNavLinks()}</> : renderDefaultNavLinks()}
                        <Divider />
                        {renderUserLinks()}
                    </div>
                </Drawer>
                <LanguagePicker title={resource[culture].language} />
            </>
        );
    };

    const renderLoggedInNavLinks = () => {
        return (
            <>
                {loggedInNavLinks.map((link) => {
                    if (link.roles.includes(appContext.roleName!)) {
                        return (
                            <Link
                                key={link.key}
                                {...{
                                    component: NavLink,
                                    to: link.to,
                                    className: classes.navLink,
                                    activeClassName: classes.navLinkActive,
                                    color: "inherit",
                                    style: { textDecoration: "none" }
                                }}>
                                <MenuItem onClick={link.onClick} className={classes.menuItem}>
                                    {link.icon ?? link.icon}
                                    {link.buttonTitle}
                                </MenuItem>
                            </Link>
                        );
                    }
                })}
            </>
        );
    };

    const renderUserLinks = () => {
        return (
            <>
                {userLinks.map((link) => (
                    <Link
                        key={link.key}
                        {...{
                            component: NavLink,
                            to: link.to,
                            className: classes.navLink,
                            activeClassName: classes.navLinkActive,
                            color: "inherit",
                            style: { textDecoration: "none" }
                        }}>
                        <MenuItem onClick={link.onClick} className={classes.menuItem}>
                            {link.icon ?? link.icon}
                            {link.buttonTitle}
                        </MenuItem>
                    </Link>
                ))}
            </>
        );
    };

    const renderDefaultNavLinks = () => {
        return (
            <>
                {guestNavLinks.map((link) => (
                    <Link
                        key={link.key}
                        {...{
                            component: NavLink,
                            to: link.to,
                            className: classes.navLink,
                            activeClassName: classes.navLinkActive,
                            color: "inherit",
                            style: { textDecoration: "none" }
                        }}>
                        <MenuItem onClick={link.onClick} className={classes.menuItem}>
                            {link.buttonTitle}
                        </MenuItem>
                    </Link>
                ))}
            </>
        );
    };

    return (
        <header className={classes.header}>
            <AppBar elevation={8} className={classes.header}>
                <Toolbar className={classes.toolbar}>{state.mobileView ? displayMobile() : displayDesktop()}</Toolbar>
            </AppBar>
        </header>
    );
}
