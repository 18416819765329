export interface ILayoutResourceStrings {
    accessKey: string;
    value: string;
    lastChanged: string;
    edit: string;
    stopEdit: string;
    newPassword: string;
    save: string;
    required: string;
    success: string;
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings;
}

const engb = "en-GB";
const etee = "et-EE";
const ruru = "ru-RU";

export const AccessKeyManagementTranslations: ILayoutResources = {
    [engb]: {
        accessKey: "Guest password",
        value: "Password:",
        lastChanged: "Last changed:",
        edit: "Change password",
        stopEdit: "Stop editing",
        newPassword: "New password:",
        save: "Save changes",
        required: "Password can't be empty",
        success: "Password successfully changed!"
    },
    [ruru]: {
        accessKey: "Гостевой пароль",
        value: "Пароль:",
        lastChanged: "Последнее изменение:",
        edit: "Изменить пароль",
        stopEdit: "Остановить редактирование",
        newPassword: "Новый пароль:",
        save: "Сохранить изменения",
        required: "Пароль не может быть пустым",
        success: "Пароль успешно изменен!"
    },
    [etee]: {
        accessKey: "Külaliste parool",
        value: "Parool:",
        lastChanged: "Viimati muudetud:",
        edit: "Muuda parooli",
        stopEdit: "Lõpeta muutmine",
        newPassword: "Uus parool:",
        save: "Salvesta muudatus",
        required: "Parool ei saa olla tühi",
        success: "Parool on uuendatud."
    }
};

export default AccessKeyManagementTranslations;
