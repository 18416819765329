import Axios from "axios";
import IVisitation from "../model/IVisitation";
import { IVisitationPage } from "../model/IVisitationPage";
import IVisitationPostDTO from "../model/IVisitationPostDTO";

export abstract class VisitationService {
    private static axios = Axios.create({
        baseURL: process.env.REACT_APP_URLPATH + "visitation",
        headers: {
            "Content-Type": "Application/json"
        }
    });

    static async getActiveVisitations(jwt: string): Promise<IVisitation[]> {
        const url = "active";
        try {
            const response = await this.axios.get<IVisitation[]>(url, {
                headers: {
                    Authorization: "Bearer " + jwt
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            return [];
        } catch (error) {
            console.log("error", error);
            return [];
        }
    }

    static async getAllVisitations(jwt: string, pageNumber: number, pageSize: number): Promise<IVisitationPage | null> {
        const url = "";
        try {
            const response = await this.axios.get<IVisitationPage>(url, {
                headers: {
                    Authorization: "Bearer " + jwt
                },
                params: {
                    pageNumber: pageNumber,
                    pageSize: pageSize
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            return null;
        } catch (error) {
            console.log("error", error);
            return null;
        }
    }

    static async postNewVisitation(visitation: IVisitationPostDTO): Promise<IVisitation | null> {
        const url = "create";
        try {
            const response = await this.axios.post<IVisitation>(url, visitation);
            if (response.status === 200) {
                return response.data;
            }
            return response.data;
        } catch (error) {
            console.log("error", error);
        }
        return null;
    }

    static async getSingleVisitationDetails(visitationId: number, name: string): Promise<IVisitation | null> {
        const url = "details/" + visitationId;
        try {
            const response = await this.axios.get<IVisitation>(url, {
                params: { name: name }
            });
            if (response.status === 200) {
                return response.data;
            }
            if (response.status === 400) {
                console.log("error", response.data);
            }
            return response.data;
        } catch (error) {
            console.log("error", error);
            return null;
        }
    }

    static async endVisitation(visitationId: number, name: string): Promise<number> {
        const url = "end-visitation";
        try {
            const response = await this.axios.post<void>(url, {
                id: visitationId,
                name: name
            });
            if (response.status === 200) {
                return response.status;
            }
            if (response.status === 400) {
                console.log("error", response.data);
            }
            return response.status;
        } catch (error) {
            console.log("error", error);
            return 400;
        }
    }

    static async deleteVisitation(jwt: string, visitationId: number): Promise<number> {
        const url = "" + visitationId;
        try {
            const response = await this.axios.delete<void>(url, {
                headers: {
                    Authorization: "Bearer " + jwt
                }
            });
            if (response.status === 200) {
                return response.status;
            }
            if (response.status === 400) {
                console.log("error", response.data);
            }
            return response.status;
        } catch (error) {
            console.log("error", error);
            return 400;
        }
    }
}
