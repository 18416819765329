import { makeStyles, Typography } from "@material-ui/core";

export interface IProps {
    text: string;
}

const useStyles = makeStyles(() => ({
    header: {
        padding: "2rem 1rem",
        fontWeight: "bold"
    }
}));

export default function CardHeader(props: IProps): React.ReactElement {
    const classes = useStyles();

    return (
        <Typography variant="h5" component="h5" className={classes.header}>
            {props.text}
        </Typography>
    );
}
