import { Button, makeStyles, MenuItem } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Menu } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { AppContext } from "../../../context/AppContext";

const useStyles = makeStyles(() => ({
    image: {
        height: "1.3rem",
        width: "1.3rem",
        padding: "0.5rem"
    },
    div: {
        marginTop: "auto",
        marginBottom: "auto",
        alignItems: "center"
    }
}));
interface IProps {
    title: string;
}

export default function LanguagePicker(props: IProps): React.ReactElement<IProps> {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const appContext = useContext(AppContext);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const cultures = [
        { code: "et-EE", name: "Eesti", filePath: "estonia.png" },
        { code: "en-GB", name: "English", filePath: "united-kingdom.png" },
        { code: "ru-RU", name: "Pусский", filePath: "russia.png" }
    ];

    const handleClose = (culture: string) => {
        if (cultures.some((x) => x.code === culture)) {
            appContext.setCulture(String(culture));
        }
        setAnchorEl(null);
    };

    return (
        <div className={classes.div}>
            <Button
                style={{ color: "#FFF", textTransform: "capitalize" }}
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}>
                {cultures.find((x) => x.code === appContext.selectedCulture)?.filePath ? (
                    <img
                        alt={"culture"}
                        className={classes.image}
                        src={"/images/" + cultures.find((x) => x.code === appContext.selectedCulture)!.filePath}
                    />
                ) : (
                    <>
                        <LanguageIcon /> &nbsp;{props.title}
                    </>
                )}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}>
                {cultures.map((culture) => (
                    <MenuItem key={culture.code} onClick={() => handleClose(culture.code)}>
                        <img alt={culture.code} className={classes.image} src={"/images/" + culture.filePath} /> {culture.name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
