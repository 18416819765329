export interface ILayoutResourceStrings {
    enter: string;
    password: string;
    codeExpired: string;
    enterCode: string;
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings;
}

const engb = "en-GB";
const etee = "et-EE";
const ruru = "ru-RU";

export const GuestAuthorizationPromptTranslations: ILayoutResources = {
    [engb]: {
        enter: "Enter",
        password: "Password",
        codeExpired: "Password has expired",
        enterCode: "Enter password"
    },
    [ruru]: {
        enter: "Входить",
        password: "Пароль",
        codeExpired: "Срок действия пароля истек",
        enterCode: "Введите пароль"
    },
    [etee]: {
        enter: "Sisene",
        password: "Salasõna",
        codeExpired: "Salasõna on aegunud",
        enterCode: "Sisesta salasõna"
    }
};

export default GuestAuthorizationPromptTranslations;
