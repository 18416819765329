import VisitationContextProvider from "../context/VisitationContextProvider";
import ActiveVisitations from "./ActiveVisitations";

export default function ActiveVisitationContextWrapper(): React.ReactElement {
    return (
        <VisitationContextProvider>
            <ActiveVisitations />
        </VisitationContextProvider>
    );
}
