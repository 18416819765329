import React from "react";
import ILoginResponse from "../model/ILoginResponse";

export interface IAppContext {
    jwt: string | null;
    username: string;
    selectedCulture: string;
    isAuthorizedGuest: boolean;
    isRegisteredGuest: boolean;
    isAuthenticated: boolean;
    roleName: string | null;
    guestName: string | null;
    guestAuthenticationTime: string | null;
    visitationId: number | null;
    setJwt: (jwt: string) => void;
    setCulture: (culture: string) => void;
    setIsAuthorizedGuest: (val: boolean) => void;
    setIsRegisteredGuest: (val: boolean) => void;
    login: (username: string, password: string) => Promise<ILoginResponse | null>;
    setUserName: (userName: string) => void;
    setGuestNameAndVisitationId: (guestName: string, visitationId: number) => void;
    setRoleName: (roleName: string) => void;
    setAll: (
        jwt: string | null,
        username: string,
        selectedCulture: string,
        isAuthorizedGuest: boolean,
        isRegisteredGuest: boolean,
        roleName: string | null,
        guestName: string | null,
        visitationId: number | null
    ) => void;
    clearAll: () => void;
}

export const AppContextInitialState: IAppContext = {
    login(): Promise<ILoginResponse | null> {
        return Promise.resolve(null);
    },
    jwt: null,
    isAuthorizedGuest: false,
    isRegisteredGuest: false,
    isAuthenticated: false,
    username: "",
    visitationId: null,
    roleName: "",
    guestName: "",
    guestAuthenticationTime: null,
    selectedCulture: "et-EE",
    setJwt: () => undefined,
    setCulture: () => undefined,
    setIsAuthorizedGuest: () => undefined,
    setIsRegisteredGuest: () => undefined,
    setUserName: () => undefined,
    setGuestNameAndVisitationId: () => undefined,
    setRoleName: () => undefined,
    setAll: () => undefined,
    clearAll: () => undefined
};

export const AppContext = React.createContext<IAppContext>(AppContextInitialState);
