import { useEffect, useState } from "react";

export default function Clock(): React.ReactElement {
    const [time, setTime] = useState(new Date());

    const refreshClock = () => {
        setTime(new Date());
    };

    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return () => {
            clearInterval(timerId);
        };
    });

    return <>{time.toLocaleTimeString()}</>;
}
