import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useContext, useState } from "react";
import IVisitation from "../../../model/IVisitation";
import { StyledTableCell, StyledTableRow } from "../shared/VisitationStyles";
import CollapsibleDetails from "../shared/CollapsibleDetails";
import { DateFormatter } from "../../../utils/DateUtils";
import { VisitationService } from "../../../services/VisitationService";
import { AppContext } from "../../../context/AppContext";
import { VisitationContext } from "../context/VisitationContext";
import VisitationActionDialog from "../shared/VisitationActionDialog";
import VisitationHistoryRowTranslations from "../../../translations/visitationHistory/VisitationHistoryRow";

export interface IProps {
    i: number;
    row: IVisitation;
}
export default function VisitationHistoryRow(props: IProps): React.ReactElement<IProps> {
    const [open, setOpen] = useState(false);
    const appContext = useContext(AppContext);
    const resource = VisitationHistoryRowTranslations;
    const culture = appContext.selectedCulture;

    const visitationContext = useContext(VisitationContext);
    const [dialogOpen, setDialogOpen] = useState(false);

    const deleteVisitation = async () => {
        const response = await VisitationService.deleteVisitation(appContext.jwt!, props.row.id);
        if (response === 200) {
            const visitationsList = visitationContext.visitations;
            setOpen((open) => !open);
            visitationsList.splice(props.i, 1);
            visitationContext.setVisitations(visitationsList);
        }
    };

    return (
        <>
            <StyledTableRow
                key={props.i}
                sx={{ "& > *": { borderBottom: "unset", border: 0, background: props.i % 2 === 0 ? "#efefef" : "#FCFCFC" } }}>
                <StyledTableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    {props.row.name}
                </StyledTableCell>
                <StyledTableCell>{props.row.companyName}</StyledTableCell>
                <StyledTableCell>{DateFormatter(new Date(props.row.arrived))}</StyledTableCell>
                <StyledTableCell>{props.row.departed ? DateFormatter(new Date(props.row.departed)) : ""}</StyledTableCell>
                <StyledTableCell>{props.row.phoneNumber}</StyledTableCell>
            </StyledTableRow>

            <CollapsibleDetails
                visitationAction={deleteVisitation}
                row={props.row}
                collapsibleOpen={open}
                setCollapsibleOpen={setOpen}
                setDialogOpen={setDialogOpen}
                historyPage={true}
            />
            <VisitationActionDialog
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                visitationAction={deleteVisitation}
                headerText={resource[culture].deleteVisitation}
                contextText={resource[culture].contextText}
                buttonText={resource[culture].buttonText}
                cancelText={resource[culture].cancelText}
            />
        </>
    );
}
